import * as React from "react";
import {
  Add,
  ArrowBackIos,
  ArrowLeft,
  Article,
  ArticleOutlined,
  Create,
  Filter,
  FilterList,
  PlusOne,
  RemoveRedEye,
  Search,
  Settings,
} from "@mui/icons-material";
import {
  Avatar,
  Button,
  Card,
  CardActions,
  CardContent,
  Chip,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  ListItemButton,
  ListItemSecondaryAction,
  OutlinedInput,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import AppNav from "../../Layout/Appbar/Index";
import "./style.css";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemIcon from "@mui/material/ListItemIcon";
import axios from "axios";
import ListItemText from "@mui/material/ListItemText";
import TradeViewChart from 'react-crypto-chart';

import { QRCode } from "react-qrcode-logo";
import Autocomplete from "@mui/material/Autocomplete";
import { Send } from "../../Components/Send";
import {
  useGetCryptoDetailsQuery,
  useGetCryptoHistoryQuery,
} from "../../Services/crypto";
import "./style.css";
import { History } from "../../Components/History";
import useAxios from "../../hooks/useAxios";
import Chart, { Charts } from "../../Components/Chart";
import { useHistory } from "react-router-dom";
import { Exchange } from "../../Components/Exchange";
import { generateDepositeAdress, getBtcAddress, getDepositeAdress, getEthAddress, getUserAccounts, userTransacctions } from "../../Services/requests";


const url =
  "https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&order=market_cap_desc&per_page=50&page=1&sparkline=true";

export const Wallets = () => {
  const [value, setValue] = React.useState("1");
  const [coins, setCoins] = React.useState([]);
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const [spacks, setSpacks] = React.useState([]);
  const [acconts, setAccounts] = React.useState([]);
  const [transaction, setTransaction] = React.useState([]);
  const [coin, setCoin] = React.useState({
    name: "Bitcoin",
    symbol: "BTC"
  });

  const [id, setId] = React.useState("");
  const [selectedCoin, setSelectedCoin] = React.useState("bitcoin");
  const coinId = "Qwsogvtv82FCd";
  const [timeperiod, setTimeperiod] = React.useState("7d");

  const [symbol, setSymbol] = React.useState("BTC");

  const [xpub, setXpub] = React.useState("");
  const [accountId, setAccountId] = React.useState("");


  const history = useHistory();

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  React.useEffect(() => {
    const fetchData = () => {
      axios
        .get(url)
        .then((response) => {
          setCoins(response.data);
          let data = response.data;
          setSpacks(data[0].sparkline_in_7d.price);
        })
        .catch((error) => {
          console.log(error);
        });
    };
    const fetchAccount = async () => {
      const res = await getUserAccounts();
      setAccounts(res);
    }

    const fetchTrans = async () => {
      const res = await userTransacctions();
      setTransaction(res);
      console.log("res", res)
    }

    fetchData();
    fetchAccount();
    fetchTrans()
  }, []);

  const firstItem = [
    {
      name: "Wallets",
      to: "wallets",
    },
    {
      name: "Buy & Sell",
      to: "buysell",
    },
    {
      name: "Earn",
      to: "earn",
    },
    {
      name: "Setting",
      to: "setting",
    },
    {
      name: "Notification",
      to: "notification",
    },
  ];
  const filterAccountId = (item) => {
    return item.currency === symbol;

  }
  const handleListItemClick = async (event, index, item) => {
    let sym = item.symbol.toUpperCase();
    setSelectedIndex(index);
    setSelectedCoin(item.name);
    setCoin(item);
    setSymbol(sym);


    const arr = acconts?.filter(item => item.currency === sym);
    console.log("kk", arr)
    setXpub(arr?.xpub);
    setAccountId(arr[0]?.id);
   
      await generateDepositeAdress(arr[0]?.id);
      const res = await getDepositeAdress(arr[0]?.id);
      console.log("rett", res.data);

      setId(res.data[0]?.address);

    





  };

  const fetchData = async () => {
    const { data } = await axios.get(`https://api.coingecko.com/api/v3/coins/${selectedCoin}?localization=false`);


  };



  React.useEffect(() => {

    fetchData();

  }, []);
  const handleNextTab = () => {
    let values = value;
    if (values !== 1) {
      let vl = 3
      setValue(vl)
    }
  }

  // const handleTabChange = (value) => {
  //   setValue(value)
  // }

  const handleActive = () => {
    setValue(value)
  }



  return (
    <div className="">
      <AppNav firstItem={firstItem} />
      <Box className="e7" sx={{ backgroundColor: 'primary.body' }}>
        <Grid container sx={{ pt: 5 }} className="bodys" space={4}>
          <Grid item xs={12} md={5}>
            <Box display={"flex"} justifyContent={"center"}>
              <Card sx={{ minWidth: "320px" }} variant="outlined">
                <CardContent sx={{ minHeight: "400px", width: "400px", px: 0 }}>
                  <Box sx={{ p: 2 }}>
                    <Box
                      display={"flex"}
                      alignContent="center"
                      justifyContent={"space-between"}
                    >
                      <Box
                        display={"flex"}
                        alignContent="center"
                        justifyContent={"start"}
                      >
                        <Search
                          sx={{ width: 40, height: 30, color: "#B8BABC" }}
                        />
                        <FilterList
                          sx={{ width: 40, height: 30, color: "#B8BABC" }}
                        />
                        <Add sx={{ width: 40, height: 30, color: "#B8BABC" }} />
                        <Settings
                          sx={{ width: 40, height: 30, color: "#B8BABC" }}
                        />
                      </Box>
                    </Box>
                  </Box>
                  <Divider sx={{ mt: 2 }} />
                  <List>
                    {coins.filter((item) => item.symbol === "btc" || item.symbol === "eth" || item.symbol === "matic" || item.symbol === "doge" || item.symbol === "ada" || item.symbol === "bch" || item.symbol === "celo" || item.symbol === "tron" || item.symbol === "flow" || item.symbol === "ltc" || item.symbol === "xdc" || item.symbol === "usdt").map((item, idx) => (
                      <ListItemButton
                        sx={{ p: 2 }}
                        key={item.symbol}
                        selected={item.symbol === coin.symbol}
                        onClick={(event) =>
                          handleListItemClick(event, idx, item)
                        }
                      >
                        <ListItemAvatar>
                          <Avatar>
                            <img src={item.image} width="100%" />
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText sx={{ color: "#455A64" }}>
                          {item.name}{" "}
                          <sup
                            style={{
                              color: "#C0BFC2",
                              fontWeight: "bold",
                              fontSize: "10px",
                            }}
                          >
                            {String(item.symbol).toUpperCase()}
                          </sup>{" "}
                        </ListItemText>

                        <ListItemSecondaryAction>
                          <Typography fontWeight={"bold"}>0</Typography>
                          <Typography
                            sx={{ color: "#C0BFC2", fontSize: "12px" }}
                          >
                            $0
                          </Typography>
                        </ListItemSecondaryAction>
                      </ListItemButton>
                    ))}
                  </List>
                </CardContent>
              </Card>
            </Box>
          </Grid>
          <Grid item xs={12} md={7}>
            <Box display={"flex"} justifyContent={"center"}>
              <Box sx={{ width: "100%", typography: "body1" }}>
                <TabContext value={value}>
                  <Card
                    sx={{
                      borderBottom: 1,

                      borderColor: "divider",

                    }}
                    variant="outlined"

                  >
                    <TabList onChange={handleTabChange}>
                      <Tab label="History" value="1" onActive={handleActive.bind(this, 0)} />
                      <Tab label="Recieve" value="2" onActive={handleActive.bind(this, 1)} />
                      <Tab label="Send" value="3" onActive={handleActive.bind(this, 2)} />
                      <Tab label="Exchange" value="4" onActive={handleActive.bind(this, 3)} />
                    </TabList>
                  </Card>
                  <TabPanel value="1" sx={{ p: 0 }}>
                    <History coins={coins} transactions={transaction} />
                  </TabPanel>
                  <TabPanel value="2" sx={{ p: 0 }}>
                    {
                      acconts?.filter(data => data.currency === symbol).map(item => (
                        <Card variant="outlined">
                          <CardContent>
                            <Box
                              display={"flex"}
                              alignContent="center"
                              sx={{ ml: 1, mb: 4 }}
                            >
                              <Typography fontWeight={"bold"} color="primary">
                                {coin?.name}
                              </Typography>
                              <Create sx={{ mx: 1, color: "#C0BFC2" }} />
                              <RemoveRedEye sx={{ color: "#C0BFC2" }} />
                            </Box>
                            <Grid container>
                              <Grid item xs={12} md={4}>
                                <Box>
                                  <QRCode value={id} />
                                </Box>
                              </Grid>
                              <Grid item xs={12} md={8}>
                                <Typography fontSize={"14px"} variant="body2">
                                  Wallet balance:
                                </Typography>
                                <Typography
                                  variant="body2"
                                  fontSize={"14px"}
                                  fontWeight={"bold"}
                                  sx={{ my: 1, textTransform: "uppercase" }}
                                >
                                  {item.balance.accountBalance
                                  } {coin?.symbol}
                                </Typography>
                                <Typography
                                  variant="body2"
                                  fontSize={"14px"}
                                  sx={{ color: "#C0BFC2", mb: 4, textTransform: "uppercase" }}
                                  fontWeight={"bold"}
                                >
                                  {item.balance.availableBalance
                                  } {coin?.symbol}
                                </Typography>
                                <>
                                  <Typography variant="body2" fontSize={"14px"}>
                                    Wallet address:
                                  </Typography>
                                  <Typography
                                    sx={{ mb: 2 }}
                                    fontSize={"12px"}
                                    fontWeight={"bold"}
                                    variant="body2"
                                  >
                                    {id}
                                  </Typography>
                                </>

                                {
                                  coin?.symbol === 'btc' ? (
                                    <Box >
                                      <Typography variant="body2" fontSize={"14px"}>
                                        Public key:
                                      </Typography>
                                      <Box sx={{ mr: 3 }}><Typography
                                        fontWeight={"bold"}
                                        variant="body2"
                                        fontSize={"12px"}
                                      >
                                        {
                                          item.xpub
                                        }
                                      </Typography></Box>
                                    </Box>
                                  ) : null
                                }
                                <Box
                                  display="flex"
                                  alignContent={"center"}
                                  sx={{ mt: 2 }}
                                >
                                  <Button
                                    variant="outlined"
                                    size="large"
                                    onClick={() => setValue("3")}

                                  >
                                    Send
                                  </Button>
                                  <Button
                                    variant="outlined"
                                    sx={{ mx: 4 }}
                                    size="large"
                                  >
                                    Request{" "}
                                  </Button>
                                  <Button variant="contained" size="large" onClick={() => history.push("buysell")}>
                                    Buy{" "}
                                  </Button>
                                </Box>
                              </Grid>
                            </Grid>
                          </CardContent>
                        </Card>
                      ))
                    }
                    <Card variant="outlined" sx={{ mt: 4 }}>
                      <CardContent>
                      <Charts coin={symbol.toUpperCase()} />
                      </CardContent>
                    </Card>
                    <Card variant="outlined" sx={{ mt: 4, minHeight: 600 }}>
                      <CardContent>
                        <Typography
                          variant="subtitle1"
                          color="primary"
                          fontWeight={"bold"}
                        >
                          Wallet transactions
                        </Typography>
                        <Divider />
                        <Grid container>
                          <Grid item xs={5}>
                            <TextField fullWidth variant="standard" />
                          </Grid>
                          <Grid item xs={7} className="border">
                            <TextField variant="standard" fullWidth />
                          </Grid>
                        </Grid>
                        <Divider />
                        {
                          transaction.length === 0 ? (
                            <Box sx={{ mt: 15 }}>
                              <Box display="flex" justifyContent="center" sx={{ mb: 3 }}>
                                <img src="https://guarda.co/app/b4167d4ba0df7168b2d1ed1690751cf6.svg" width="180px" />
                              </Box>
                              <Box display="flex" justifyContent="center">
                                <Typography>
                                  Top up your wallet or{" "}
                                  <span color="primary">
                                    share your public address
                                  </span>{" "}
                                  with somebody to get coins or tokensss
                                </Typography>
                              </Box>
                              <Box
                                display="flex"
                                justifyContent="center"
                                sx={{ my: 4 }}
                              >
                                <Button variant="contained" size="large">
                                  Buy Crypto
                                </Button>
                              </Box>
                              <Box display="flex" justifyContent="center">
                                <Button>Load Transaction</Button>
                              </Box>
                            </Box>
                          ) : (
                            <Box>

                            </Box>
                          )
                        }
                      </CardContent>
                    </Card>
                  </TabPanel>
                  <TabPanel value="3" sx={{ p: 0 }}>
                    <Send coins={coins} selectedIdx={selectedIndex} address={id} coin={symbol} accounts={acconts} xpub={xpub} accountId={accountId} />
                  </TabPanel>
                  <TabPanel value="4" sx={{ p: 0 }}>
                    <Exchange coins={coins} selectedIdx={selectedIndex} address={id} coin={symbol} accounts={acconts} xpub={xpub} accountId={accountId} />
                  </TabPanel>

                </TabContext>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};


