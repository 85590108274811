import * as React from "react";
import { ArrowBackIos, ArrowLeft } from "@mui/icons-material";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import AppNav from "../../Layout/Appbar/Index";
import "./style.css";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import "./style.css";
import axios from "axios";

export const Earn = () => {
  const [value, setValue] = React.useState("1");
  const [coins, setCoins] = React.useState([]);

  const url =
    "https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&order=market_cap_desc&per_page=50&page=1&sparkline=false";

  React.useEffect(() => {
    axios
      .get(url)
      .then((response) => {
        setCoins(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const firstItem = [
    {
      name: "Wallets",
      to: "wallets",
    },
    {
      name: "Buy & Sell",
      to: "buysell",
    },
    {
      name: "Earn",
      to: "earn",
    },
    {
      name: "Setting",
      to: "setting",
    },
  ];

  return (
    <div className="">
      <AppNav firstItem={firstItem} />

      <Box className="e7" sx={{backgroundColor: 'primary.body'}}>
        <Grid container sx={{ pt: 7 }} className="bodys">
          <Grid item xs={12}>
            <Box display={"flex"} justifyContent={"center"}>
              <Card sx={{ minWidth: "100%" }} variant="outlined">
                <CardContent sx={{ minHeight: "600px", px: 0 }}>
                  <TabContext value={value}>
                    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                      <TabList
                        onChange={handleChange}
                        aria-label="lab API tabs example"
                      >
                        <Tab label="Start New Staking" value="1" />
                      </TabList>
                    </Box>
                    <TabPanel value="1" sx={{ p: 0 }}>
                      <TableContainer>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                          <TableHead sx={{ bgcolor: "primary.body", }}>
                            <TableRow>
                              <TableCell className="table__header">
                                COIN
                              </TableCell>
                              <TableCell className="table__header">
                                LOCK-UP PERIOD APY
                              </TableCell>
                              <TableCell className="table__header">
                                APY
                              </TableCell>
                              <TableCell className="table__header">
                                MIN AMOUNT
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {coins?.map((item, id) => (
                              <TableRow
                                key={id}
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                  "&:hover": {
                                    backgroundColor: "#EBF2FF !important",
                                  },
                                }}
                              >
                                <TableCell key={id} scope="row">
                                  <Box display="flex" alignContent={"center"}>
                                    <img
                                      src={item.image}
                                      alt="image"
                                      width={"45px"}
                                    />
                                    <Typography
                                      sx={{ ml: 1, mt: 1 }}
                                      fontWeight="bold"
                                    >
                                      {item.name}
                                    </Typography>
                                  </Box>
                                </TableCell>

                                <TableCell>No Lock Up</TableCell>

                                <TableCell sx={{ fontWeight: "bold" }}>
                                  up to 0%
                                </TableCell>
                                <TableCell>no min amount</TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </TabPanel>
                  </TabContext>
                </CardContent>
              </Card>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};
