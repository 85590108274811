import * as React from "react";
import { ArrowBackIos, ArrowLeft, Lock, Logout, RateReview } from "@mui/icons-material";
import {
  Avatar,
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemSecondaryAction,
  ListItemText,
  OutlinedInput,
  TextField,
  Typography, Switch,
} from "@mui/material";
import { Box } from "@mui/system";
import AppNav from "../../Layout/Appbar/Index";
import "./style.css";


export const SettingPage = () => {

  const [dBackup, setDBackup] = React.useState(true);
  const [cPassword, setCPassword] = React.useState(false);
  const [restore, setRestore] = React.useState(false);


  const firstItem = [
    {
      name: "Wallets",
      to: "wallets",
    },
    {
      name: "Buy & Sell",
      to: "buysell",
    },
    {
      name: "Earn",
      to: "earn",
    },
    {
      name: "Setting",
      to: "setting",
    },
  ];

  const backup = [
    {
      name: "Download backup",
      icon: "",
      handle: () => { setCPassword(false); setRestore(false); setDBackup(true) }

    },
    {
      name: "Restore",
      icon: "",
      handle: () => { setCPassword(false); setDBackup(false); setRestore(true) }

    }
  ]
  const changePassword = () => {
    setDBackup(false);
    setRestore(false);
    setCPassword(true);
  }

  const logout = () => {
    localStorage.clear();
    window.location.reload();
  }
  return (
    <div className="">
      <AppNav firstItem={firstItem} />
      <Box className="e7" sx={{ backgroundColor: 'primary.body' }}>
        <Grid container sx={{ pt: 5 }} className="bodys" space={4}>
          <Grid item xs={12} md={5}>
            <Box display={"flex"} justifyContent={"center"}>
              <Card variant="outlined">
                <CardContent
                  sx={{ width: "400px", px: 0 }}
                >
                  <Typography variant="h6" fontWeight={"bold"} sx={{ color: "#455A64", p: 2 }}>Settings
                  </Typography>


                  <List>

                    <ListItemButton
                      sx={{ p: 2 }}
                      onClick={changePassword}
                    >
                      <ListItemAvatar>
                        <Avatar>
                          <Lock />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText sx={{ color: "#455A64" }}>
                        Change Password
                      </ListItemText>
                    </ListItemButton>
                  </List>


                  <Box sx={{ p: 2 }}>
                    <Typography variant="caption" sx={{ mb: 1 }}>Other</Typography>
                    <Divider />

                  </Box>
                  <ListItemButton
                    sx={{ p: 2 }}
                  >
                    <ListItemAvatar>
                      <Avatar>
                        <RateReview />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText sx={{ color: "#455A64" }}>
                      Rate Us
                    </ListItemText>

                  </ListItemButton>
                  <ListItemButton
                    sx={{ p: 2 }}
                    onClick={logout}
                  >
                    <ListItemAvatar>
                      <Avatar>
                        <Logout />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText sx={{ color: "#455A64" }}>
                      Logout
                    </ListItemText>

                  </ListItemButton>
                </CardContent>
              </Card>
            </Box>
          </Grid>
          <Grid item xs={12} md={7}>
            <Box display={"flex"} justifyContent={"center"}>
              <Card sx={{ minWidth: "100%" }} variant="outlined">


                <CardContent sx={{}}>
                  <Box display="flex" justifyContent={"center"} alignItems="center">
                    <Box sx={{ width: 270 }}>
                      <Typography variant="h6" fontWeight={"bold"} textAlign={"center"}>Change Password
                      </Typography>
                      <Typography sx={{ mt: 3 }} variant="subtitle2" textAlign={"center"}>
                        The new password will only work with
                        the new backup that you need to download

                      </Typography>
                      <Typography textAlign={"center"} variant="subtitle2" sx={{ mt: 4 }} fontWeight={"bold"}>⚠️⚠️⚠️ Pesa Wyse cannot recover the password.️
                      </Typography>

                      <Box display="flex" flexDirection={"column"} alignItems="center" justifyContent={"center"} sx={{ mt: 4 }}>
                        <FormControl variant="outlined" sx={{ width: "100%" }}>
                          <InputLabel htmlFor="outlined-adornment-password">old password</InputLabel>
                          <OutlinedInput
                            placeholder="Enter old password"
                            label="old password"
                          />
                        </FormControl>
                        <FormControl variant="outlined" sx={{ width: "100%", my: 4 }}>
                          <InputLabel htmlFor="outlined-adornment-password">new password</InputLabel>
                          <OutlinedInput
                            placeholder="Enter new password"
                            label="new password"
                          />
                        </FormControl>
                        <FormControl variant="outlined" sx={{ width: "100%", }}>
                          <InputLabel htmlFor="outlined-adornment-password">repeat new password</InputLabel>

                          <OutlinedInput
                            placeholder="Enter new password"
                            label="repeat , new password"
                          />
                        </FormControl>

                        <Box sx={{ mt: 3 }} display="flex" justifyContent={"center"}>
                          <Button variant="contained" size="large">Change Password</Button>

                        </Box>


                      </Box>

                    </Box>

                  </Box>
                </CardContent>

              </Card>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};
