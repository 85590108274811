import React, { useState } from "react";

import { ArrowBackIos, ArrowLeft } from "@mui/icons-material";
import {
    Button,
    Card,
    CardActions,
    CardContent,
    Divider,
    FormControl,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    TextField,
    Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';


export const CreateWalletPassword = ({ submit }) => {
    const [values, setValues] = React.useState({

        password: '',
        confirmPassword: '',
        showConfirmPassword: false,
        showPassword: false,
    });

    const [toggle1, setToggle1] = useState(false);
    const [toggle2, setToggle2] = useState(false);
    let password;

    const formSchema = Yup.object().shape({
        password: Yup.string()
            .required("Password is required")
            .min(4, "Password length should be at least 4 characters")
            .max(12, "Password cannot exceed more than 12 characters"),
        cpassword: Yup.string()
            .required("Confirm Password is required")
            .min(4, "Password length should be at least 4 characters")
            .max(12, "Password cannot exceed more than 12 characters")
            .oneOf([Yup.ref("password")], "Passwords do not match")
    });

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
        watch,
        getValues
    } = useForm({
        mode: "onTouched",
        resolver: yupResolver(formSchema)
    });
    password = watch("password", "");

    const onSubmit = (data) => submit(data.password)

    console.log(errors);





    return (

        <Grid container  className='bodys'>
            <Grid item xs={12} sx={{mt:3}}>
                <Box display={"flex"} justifyContent={"center"}>
                    <Card
                        sx={{ width: 600, borderRadius: "0px" }}
                        elevation={0}
                        raised
                    >
                        <CardContent sx={{ p: 5 }}>
                            <Box sx={{ mt: 4 }}>
                                <Typography fontWeight={"bold"} variant="h6" sx={{ mb: 1 }}>
                                    Protect your wallet with a password
                                </Typography>

                                <Typography variant="body2">
                                    The password you enter encrypts your private key and gives
                                    access to your funds. Please store your password properly.
                                    Pesa Wyse does not keep nor able to restore it for security
                                    reasons.
                                </Typography>
                            </Box>

                            <Box sx={{ mt: 3 }}>
                                <FormControl variant="outlined" sx={{ width: "100%" }}>
                                    <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>

                                    <OutlinedInput

                                        {...register("password")}
                                        type={toggle1 ? "text" : "password"}
                                        placeholder="Password"
                                        label="Password"
                                    />
                                    <span style={{ color: 'red', fontSize: '12px' }}>{errors.password?.message}</span>
                                </FormControl>

                                <FormControl variant="outlined" sx={{ width: "100%", mt: 4 }}>
                                    <InputLabel htmlFor="outlined-adornment-password">Confirm Password</InputLabel>

                                    <OutlinedInput
                                        type={toggle2 ? "text" : "password"}
                                        {...register("cpassword")}
                                        label="Confirm Password"
                                        placeholder="Confirm Password"

                                    />
                                    <span className="alerts" style={{ color: 'red', fontSize: '12px' }}>{errors.cpassword?.message}</span>
                                </FormControl>
                            </Box>


                            <Box
                                display={"flex"}
                                justifyContent={"space-between"}
                                sx={{ mt: 4 }}
                            >
                                <Box display={"flex"}>
                                    <ArrowBackIos color="primary" />
                                    <Typography color={"primary"}>Back</Typography>
                                </Box>
                                <Button variant="contained" size="large" onClick={handleSubmit(onSubmit)} >
                                    {" "}
                                    i've written it down
                                </Button>
                            </Box>
                        </CardContent>
                    </Card>
                </Box>
            </Grid>

        </Grid>

    )
}