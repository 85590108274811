import * as React from "react";
import { AccessTimeOutlined, ArrowBackIos, ArrowLeft } from "@mui/icons-material";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import AppNav from "../../Layout/Appbar/Index";
import "./style.css";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import "./style.css";
import { Charts } from "../../Components/Chart";
const currencies = [
  {
    value: 'USD',
    label: '$',
  },
  {
    value: 'EUR',
    label: '€',
  },
  {
    value: 'BTC',
    label: '฿',
  },
  {
    value: 'JPY',
    label: '¥',
  },
];

export const BuySell = () => {
  const [currency, setCurrency] = React.useState('EUR');
  const handleChange = (event) => {
    setCurrency(event.target.value);
  };



  const firstItem = [
    {
      name: "Wallets",
      to: "wallets",
    },
    {
      name: "Buy & Sell",
      to: "buysell",
    },
    {
      name: "Earn",
      to: "earn",
    },
    {
      name: "Setting",
      to: "setting",
    },
  ];

  return (
    <div className="">
      <AppNav firstItem={firstItem} />
      <Box className="e7" sx={{ backgroundColor: 'primary.body' }}>
        <Box className="bodys">
          <Grid container sx={{ pt: 7 }} spacing={3}>
            <Grid item xs={12} md={7}>
              <Box display={"flex"} justifyContent={"center"}>
                <Card sx={{ minWidth: "100%" }} variant="outlined">
                  <CardContent
                    sx={{ height: "500px", }}
                  >
                    <Typography fontWeight={"bold"} variant="h6">Buy & Sell</Typography>
                    <Grid container>
                      <Grid item xs={12} sx={{ mt: 5 }}>
                        <Box display={"flex"} alignContent="center" >
                          <TextField variant="outlined" size="large" select
                            label="Amount"
                            value={currency}
                            fullWidth
                            className="TextField-without-border-radius"

                            onChange={handleChange}
                          >
                            {currencies.map((option) => (
                              <MenuItem key={option.value} value={option.value}>
                                {option.label}
                              </MenuItem>
                            ))}
                          </TextField>
                          <TextField variant="outlined" className="TextField-without-border-radius" size="large" fullWidth />
                        </Box>
                      </Grid>
                      <Grid item xs={12} sx={{ mt: 5 }}>
                        <Box display={"flex"} alignContent="center" >
                          <TextField variant="outlined" size="large" select
                            label="Amount"
                            value={currency}
                            fullWidth
                            className="TextField-without-border-radius"

                            onChange={handleChange}
                          >
                            {currencies.map((option) => (
                              <MenuItem key={option.value} value={option.value}>
                                {option.label}
                              </MenuItem>
                            ))}
                          </TextField>
                          <TextField variant="outlined" className="TextField-without-border-radius" size="large" fullWidth />
                        </Box>
                      </Grid>
                      <Grid xs={12}>
                        <Box display={"flex"} justifyContent="space-between" alignContent="center" sx={{ mt: 2 }}>
                          <Typography>Payment partner
                          </Typography>
                        </Box>
                        <Box display={"flex"} justifyContent="space-between" alignContent="center" sx={{ mt: 4 }}>
                          <Box display={"flex"} alignContent="center" justifyContent={"center"}>
                            <AccessTimeOutlined sx={{ fontSize: 20 }} />
                            <Typography variant="subtitle2">Estimated arrival: 10 - 30 mins
                            </Typography>
                          </Box>
                          <Button variant="contained" size="large" sx={{ px: 6 }}>Buy</Button>
                        </Box>
                        <Box sx={{ mt: 5 }}>
                          <Box>
                            <Typography variant="subtitle2">Optimal purchase rate starts for amounts from 3 879 293.137 .</Typography>
                            <Typography variant="subtitle2">Choose the currency of your card to reduce the bank fee (if available).
                            </Typography>
                          </Box>
                          <Box>

                          </Box>
                        </Box>

                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Box>
            </Grid>
            <Grid item xs={12} md={5}>
              <Card variant="outlined">
                <CardContent  >
                  <Charts  coin={"BTC"}/>
                </CardContent>
              </Card>

            </Grid>
          </Grid>
        </Box>
      </Box>

    </div>

  );
};
