import {
  Button,
  Card,
  CardContent,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import "./style.css";
import trustPilot from "../../assets/trust.png"
import CircleAnimate from "../Core/HeroVector";
import { SlowMotionVideo } from "@mui/icons-material";
import { useHistory } from "react-router-dom";

export const Hero = () => {
  const history = useHistory();
  return (
    <div className="container">
      <Grid
        container
        justifyContent="space-between"
        alignItems={"center"}
        alignContent="center"
        className="bodys"
      >
        <Grid item sx={12} md={6}>
          <Box >
            <Box sx={{}}>
              <Typography fontWeight={"bold"} variant="h3" justify="center" >
                The Best Exchange to Buy, Sell and Trade Crypto
              </Typography>
            </Box>
            <Typography variant="body1">Stake, exchange, earn and buy Bitcoin, Ethereum and thousands of other assets.</Typography>
          </Box>
          <Box sx={{ mt: 4, mb: 3 }} display="flex" alignItems={"center"}>


            <Button variant="contained" size="large" sx={{ borderRadius: '30px' }} onClick={()=> history.push("/login")}>
              <Typography variant="">Get Started</Typography>
            </Button>
            <Box display="flex" alignItems={"center"}>
              <SlowMotionVideo sx={{mr:1,ml:2, fontSize: "35px", }} color="primary"/>
              <Typography>How it works</Typography>

            </Box>
          </Box>

        </Grid>
        <Grid item xs={12} md={6} sx={{ mb: 3 }}>
          {/* <img src="https://guarda.com/assets/images/home-hero__devices.png" alt="image" width={"100%"} /> */}
          <CircleAnimate />
        </Grid>
      </Grid>
    </div>
  );
};
