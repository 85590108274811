import { Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";

const nav = [
  {
    name: "About Us",
    items: [
      {
        name: "About",
        to: "#",
      },
      {
        name: "Careers",
        to: "#",
      },
      {
        name: "Business Contacts",
        to: "#",
      },
      {
        name: "Community",
        to: "#",
      },
      {
        name: "Binance Blog",
        to: "#",
      },
      {
        name: "Terms",
        to: "#",
      },
      {
        name: "Privacy",
        to: "#",
      },
      {
        name: "Risk Warning",
        to: "#",
      },
      {
        name: "Announcements",
        to: "#",
      },
      {
        name: "News",
        to: "#",
      },
    ],
  },

  {
    name: "Products",
    items: [
      {
        name: "Exchange",
        to: "#",
      },
      {
        name: "Pesa Wyse live",
        to: "#",
      },
      {
        name: "Labs",
        to: "#",
      },
      {
        name: "LaunchPad",
        to: "#",
      },
      {
        name: "Research",
        to: "#",
      },
      {
        name: "Trust Wallet",
        to: "#",
      },
    ],
  },
  {
    name: "Service",
    items: [
      {
        name: "Downloads",
        to: "#",
      },
      {
        name: "Desktop Application",
        to: "#",
      },
      {
        name: "Buy crypto",
        to: "#",
      },
      {
        name: "Referral",
        to: "#",
      },
      {
        name: "Execution solution",
        to: "#",
      },
      {
        name: "Appliate",
        to: "#",
      },
      {
        name: "Pesa Wyse",
        to: "#",
      },
      {
        name: "Historical market data",
        to: "#",
      },
    ],
  },
  {
    name: "Support",
    items: [
      {
        name: "Give Us Feedback",
        to: "#",
      },
      {
        name: "Support Center",
        to: "#",
      },
      {
        name: "Submit a request",
        to: "#",
      },
      {
        name: "Trading Rules",
        to: "#",
      },
    ],
  },
  {
    name: "Learn",
    items: [],
  },
  {
    name: "Community",
    items: [],
  },
];

export const Footer = () => {
  return (
    <div className="bodys">
      <Grid container>
        {nav.map((item) => (
          <Grid xs={6} md={2}>
            <Box sx={{ mt: 4, mb: 2 }}>
              <Typography variant="subtitle-1" fontWeight={"bold"}>
                {item.name}
              </Typography>
            </Box>

            {item.items.map((item, idx) => (
              <Typography  key={idx} fontSize="15px" sx={{ color: "#A8AEB7", mb:1, '&:hover': {
                                color: "#000"
                            }, cursor: "pointer" }}>
                {item.name}
              </Typography>
            ))}
          </Grid>
        ))}
      </Grid>
    </div>
  );
};
