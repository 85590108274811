import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import CssBaseline from '@mui/material/CssBaseline';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import Box from '@mui/material/Box';
import logo from '../../assets/logo.png'
import { Avatar, Badge, Button, Drawer, IconButton, List, ListItem, ListItemText, Menu, MenuItem } from '@mui/material';
import './style.css'
import Fade from '@mui/material/Fade';

import { AccountBalanceOutlined, AccountBalanceWalletOutlined, KeyboardArrowDown, MenuRounded, Notifications } from '@mui/icons-material';
import { useState } from 'react';
import { useHistory } from "react-router-dom";
import { Link } from 'react-router-dom';
import axios from 'axios';


function ElevationScroll(props) {
    const { children, window } = props;

    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 0,
        target: window ? window() : undefined,
    });

    return React.cloneElement(children, {
        elevation: trigger ? 1 : 0,
        style: {
            backgroundColor: trigger ? "#fff" : "transparent",

        },


    });
}

ElevationScroll.propTypes = {
    children: PropTypes.element.isRequired,

    window: PropTypes.func,
};





export default function Navbar({ children, props }) {


    const [isDrawerOpen, setIsDrawerOpen] = useState(false)
    const [anchorEl, setAnchorEl] = useState(null);
    const [buyCrypto, setBuyCrypto] = useState(null);
    const [coins, setCoins] = useState([])
    const open = Boolean(anchorEl);
    const openBuyCrypto = Boolean(buyCrypto);


    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const closeBuyCrypto = () => {
        setBuyCrypto(null)

    };


    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleBuyCrypto = (event) => {
        setBuyCrypto(event.currentTarget)

    };
    const handleFinance = () => {

    };
    const handleExchange = () => {

    };
    const handleEarn = () => {

    };


    const nav = [

        {
            name: 'Get Started',
            to: '',
            handle: handleClick
        },

        {
            name: 'Buy Crypto',
            to: '',
            handle: handleBuyCrypto
        },
        {
            name: 'Exchange',
            to: '/exchange',
            handle: handleExchange
        },
        {
            name: 'Earn',
            to: '',
            handle: handleEarn
        },
        {
            name: 'Finance',
            to: '',
            handle: handleFinance
        }
    ];
    const url =
    "https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&order=market_cap_desc&per_page=50&page=1&sparkline=false";

    

  useEffect(() => {
    axios
      .get(url)
      .then((response) => {
        setCoins(response.data);
       
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);









    return (
        <React.Fragment>
            <CssBaseline />
            <ElevationScroll {...props}>
                <AppBar
                >
                    <Box className='custom_P'>
                        <Toolbar  >
                            <Link to={'/'}> <img src={logo} width="90" /></Link>
                            <Typography sx={{ flexGrow: 1, }}>
                            </Typography>

                            <Box sx={{ display: { xs: 'none', md: 'block', } }}>
                                {
                                    nav.map((item) => (
                                        <a className='cool-link' href={item.to} onMouseOver={item.handle} style={{ marginRight: '40px', }}>
                                            <Button sx={{ color: "#363636", cursor: "pointer" }}>{item.name}</Button>
                                        </a>

                                    ))

                                }
                            </Box>
                            <Menu
                                sx={{ mt: 2 }}
                                PaperProps={{
                                    elevation: 0,
                                    sx: {
                                        overflow: 'visible',
                                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                        mt: 1.5,
                                        width: 160
                                    },
                                }}
                                id="fade-menu"
                                MenuListProps={{
                                    'aria-labelledby': 'fade-button',
                                }}
                                anchorEl={buyCrypto}
                                open={openBuyCrypto}
                                onClose={closeBuyCrypto}
                            >
                               {coins.slice(0, 3).map((item) => (
                                <Link to={""} >
                                    <MenuItem 

                                    
                                    
                                    
                                    sx={{
                                        color: '#000',
                                        m: 1,
                                        borderRadius: "5px",
                                        '&:hover': {
                                            border: "solid 1px #E3F2FD"
                                            


                                        },
                                    }}>

                                        <Box display="flex" alignItems={"center"}>
                                            <img width="25px"  src={item.image} alt="image"/>
                                            <Typography sx={{ml:1}} variant="subtitle2">{item.name}</Typography>
                                        </Box>


                                    </MenuItem>
                                </Link>
                               ))}



                            </Menu>

                            <Menu
                                sx={{ mt: 2 }}
                                PaperProps={{
                                    elevation: 0,
                                    sx: {
                                        overflow: 'visible',
                                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                        mt: 1.5,
                                        width: 160
                                    },
                                }}
                                id="fade-menu"
                                MenuListProps={{
                                    'aria-labelledby': 'fade-button',
                                }}
                                anchorEl={anchorEl}
                                open={open}
                                onClose={handleClose}
                                TransitionComponent={Fade}
                            >

                                <Link to={"/login"} >
                                    <MenuItem sx={{
                                        color: '#000',
                                        m:1,
                                        borderRadius: "5px",
                                        '&:hover': {
                                            backgroundColor: 'primary.dark',
                                            color: "#fff"


                                        },
                                    }}>

                                        Online Wallet

                                    </MenuItem>
                                </Link>



                            </Menu>
                            



                            {/* <Box sx={{ display: { xs: 'none', md: 'block', } }}>

                                <a href='/app' className='anchor' >
                                    <Button variant="contained" sx={{ fontSize: '17px', mr: 2, px: 4, textTransform: "capitalize" }} size="large" >Launch app </Button>
                                </a>


                            </Box> */}
                            <IconButton
                                sx={{ display: { xs: 'block', md: 'none', } }}

                                onClick={() => setIsDrawerOpen(true)}

                            >
                                <MenuRounded />

                            </IconButton>
                        </Toolbar>
                        <Drawer open={isDrawerOpen} onClose={() => setIsDrawerOpen(false)} PaperProps={{
                            sx: { width: "90%" },
                        }}>
                            <List >
                                {
                                    nav.map((item) => (

                                        <ListItem >
                                            <Link to={item.to}>
                                                <a className='anchor'>{item.name}</a>
                                            </Link>
                                        </ListItem>

                                    ))
                                }

                                <Box display="flex" flexDirection={"column"} sx={{
                                    px: 2, mt: 2, mb: 2
                                }}>


                                    <a href='/app' className='anchor' >
                                        <Button fullWidth variant="contained" sx={{ fontSize: '12px', px: 2, textTransform: "capitalize" }} size="small" >Launch app </Button>
                                    </a>



                                </Box>



                            </List>
                        </Drawer>
                    </Box>
                </AppBar>

            </ElevationScroll>
            <Toolbar />

            <Box sx={{ mt: 4 }}>
                {children}

            </Box>


        </React.Fragment>
    );
}