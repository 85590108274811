import { To_LIGHT, To_DARK } from './actionTypes'

const initialState = {
    status: false,
};

export const themeReducer = (state = initialState, action) => {
    switch (action.type) {
        case To_LIGHT:
            return {
                ...state,
                status: state.status = false,
            };

        case To_DARK:
            return {
                ...state,
                status: state.status = true,
            };
        default:
            return state;
    }
};