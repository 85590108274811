import { Avatar, Button, Card, CardContent, CircularProgress, Divider, Grid, Typography } from "@mui/material";
import { Box, } from "@mui/system";
import axios from "axios";
import { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import { Chart, registerables } from 'chart.js';
import { BCircularProgress } from "@mui/material";
import { useParams } from "react-router-dom";
import Navbar from "../../Layout/Navbar/Navbar";
import { Footer } from "../../Layout/Footer/Index";

Chart.register(...registerables);



export const CoinDetail = () => {
    const [historicData, setHistoricData] = useState();
    const [days, setDays] = useState(1);
    const [data, setData] = useState();
    const { currency } = 'USD';
    const [flag, setflag] = useState(false);
    const id = useParams();

    const fetchHistoricData = async () => {
        const { data } = await axios.get("https://api.coingecko.com/api/v3/coins/bitcoin/market_chart?vs_currency=usd&days=30");
        setflag(true);
        setHistoricData(data.prices);
    };
    const fetchData = async () => {
        const { data } = await axios.get(`https://api.coingecko.com/api/v3/coins/${id.id}?localization=false`);
        console.log(">>>", data);
        setData(data);
    };



    useEffect(() => {
        fetchHistoricData();
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [days]);
    return (
        <>
            <Navbar />
            <Box class="bodys" >
                <Grid container >
                    <Grid item xs={12} md="8" sx={{mt:5}}>
                        <Card variant="outlined">

                            <CardContent>
                                <Box display="flex" alignItems={"center"} justifyContent="space-between">
                                    <Box display="flex" alignItems={"center"}>
                                        <Avatar sx={{ width: 30, height: 30 }} >
                                            <img src={data?.image?.thumb} alt="image" width={"100%"} />
                                        </Avatar>

                                        <Typography sx={{ ml: 2 }}>{data?.name} Price</Typography>


                                    </Box>
                                    

                                    <Button variant="contained">Buy {data?.name}</Button>

                                </Box>
                                <Box>
                                    <Line
                                        data={{
                                            labels: historicData?.map((coin) => {
                                                let date = new Date(coin[0]);
                                                let time =
                                                    date.getHours() > 12
                                                        ? `${date.getHours() - 12}:${date.getMinutes()} PM`
                                                        : `${date.getHours()}:${date.getMinutes()} AM`;
                                                return days === 1 ? time : date.toLocaleDateString();
                                            }),

                                            datasets: [
                                                {
                                                    data: historicData?.map((coin) => coin[1]),
                                                    label: `Price ( Past ${days} Days ) in usd`,
                                                    borderColor: "#108FF5",
                                                },
                                            ],
                                        }}
                                        options={{
                                            elements: {
                                                point: {
                                                    radius: 1,
                                                },
                                            },
                                        }}
                                    />
                                </Box>

                            </CardContent>
                        </Card>

                    </Grid>
                   


                </Grid>
            </Box>
            <Footer />
        </>
    )
}