import { Typography } from "@mui/material"
import { Box } from "@mui/system"

export const StepTwoText = ({prefixTitle, prefixSubtitle, suffixTitle, suffixSubtitle})=> {
    return (
      
        <Box display="flex" alignItems={"start"} justifyContent="space-between" sx={{mb:3}}>
        <Box>
            <Typography variant=""
                fontWeight={"bold"}
                sx={{mb:1}}
            >
                {prefixTitle}
            </Typography>
            <Typography variant="subtitle2"
            >
               {prefixSubtitle}
            </Typography>
        </Box>
        <Box display="flex" alignContent={"center"} flexDirection="column" justifyContent={"center"} alignItems="end">
            <Typography variant=""
                fontWeight={"bold"}
                sx={{mb:1}}
                
            >
                {suffixTitle}
            </Typography>
            <Typography variant="subtitle2"
            >
                {suffixSubtitle}
            </Typography>
        </Box>

    </Box>
    )
}