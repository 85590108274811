import React from "react";
import {
    Button,
    Card,
    CardContent,
    Divider,
    Grid,
    IconButton,
    InputAdornment,
    TextField,
    Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { useState } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import { CheckCircle, FilterNone, Home, Launch, QrCode, QrCode2 } from "@mui/icons-material";
import QrReader from 'react-qr-scanner';
import Rating from '@mui/material/Rating';

import { StepTwoText } from "../Core/StepTwoText";
import { generateDepositeAdress, getDepositeAdress, genPkey, getUserWallet, transaction, transferToBlockchain } from "../../Services/requests";
import { max } from "moment";

export const Send = ({ coins, selectedIdx, address, coin, accounts, xpub, accountId }) => {

    const [result, setResult] = useState();
    const [delay, setDelay] = useState(100);
    const [selected, setSelected] = useState("environment");
    const [startScan, setStartScan] = useState(false);
    const [startScan1, setStartScan1] = useState(false);
    const [loadingScan, setLoadingScan] = useState(false);
    const [data, setData] = useState("");
    const [value, setValue] = useState(0);
    const [from, setFrom] = useState("");
    const [fromSymbol, setFromSymbol] = useState("");
    const [fromCrypto, setFromCrypto] = useState("");
    const [currentAddress, setCurrentAddress] = useState("");
    const [toAdress, setToAddress] = useState("");
    const [memo, setMemo] = useState("");
    const [amount, setAmount] = useState("");
    const [step1, setStep1] = useState(true);
    const [step2, setStep2] = useState(false);
    const [step3, setStep3] = useState(false);
    const [setMemos, memos] = useState(false);
    const [aBalance, setAvailableBalance] = useState("");
    const [inputValue, setInputValue] = useState('');
    const [mnemonic, setMnemonic] = useState(null);
    const [name, setName] = useState("");
    const [hash, setHash] = useState("");
    const [privateKey, setPrivateKey] = useState(null);


    const handleChange = (e) => {
        console.log("e", e.target.value)

    }

    const handleSelect = async (props, option) => {
        const coin = coins.filter((e) => e.name === option);
        setFromSymbol(coin[0].symbol.toUpperCase());
        setFromCrypto(coin[0].name.toUpperCase())
        const acct = accounts.filter((e) => e.currency === coin[0].symbol.toUpperCase());
        const res = await getDepositeAdress(acct[0].id);
        if (res.length > 0) {

            setFrom(res[0].address);


        } else {
            const data = await generateDepositeAdress(acct[0].id);
            setFrom(data);

        }



    }

    const handleError = (err) => {
        console.error(err);
    };

    const handleScan = (data) => {
        setLoadingScan(true);

        if (data && data !== "") {
            console.log(`loaded >>>`, data);
            setData(data);
            setStartScan(false);
            setLoadingScan(false);
        }


    }
    const handleScan1 = (data) => {
        setLoadingScan(true);

        if (data && data !== "") {
            console.log(`loaded >>>`, data);
            setData(data);
            setStartScan1(false);
            setLoadingScan(false);
        }


    }
    const previewStyle = {
        height: 240,
        width: 320,
    }

    const confirm = async () => {
        setStep1(false);

        const data = {
            senderAccountId: accountId,
            address: toAdress,
            amount: amount,
            mnemonic: privateKey,
            xpub: xpub,
            


        }

        const res = await transferToBlockchain(data,name);
        if (res.completed === true) {
            setHash(res.txId)
            setStep2(false); setStep3(true);
            
        }

        // const pdata = {
        //     name: name,
        //     index: 2,
        //     mnemonic: privateKey,

        // };
        // const key = await genPkey(pdata);
        // const data = {
        //     name: name,
        //     pKey: key,
        //     fromAdress: address,
        //     toAdress: toAdress,
        //     amount: amount
        // };

        // const res = await transaction(data);
        // console.log("res", res)




    }

    const handlePasteAnywhere = event => {
        console.log(event.clipboardData.getData('text'));
    };

    React.useEffect(() => {
        availableBalance();


    }, []);


    const handleNext = async () => {
        const res = await getUserWallet();
        if (coin === 'BTC') {
            const mn = res.wallet?.btc?.mnemonic;

            setName("bitcoin");
            setPrivateKey(mn);
            console.log("ddddd", mn);
            console.log("vb", coin);
            console.log("resss", res);

        } else if (coin === 'ETH') {
            const mn = res.wallet?.eth.mnemonic;
            setName("etherium");
            setPrivateKey(mn);

        } else if (coin === 'BNB') {
            const pr = res.wallet?.bnb?.privateKey;
            setName("bnb");
            setPrivateKey(pr)

        } else if (coin === 'XRP') {
            const pr = res.wallet.xrp.secret;
            setName("xrp");
            setPrivateKey(pr)

        } else if (coin === 'DOGE') {
            const mn = res.wallet.doge.mnemonic;
            setName("dogecoin");
            setPrivateKey(mn);

        } else if (coin === 'ADA') {
            const mn = res.wallet.ada.mnemonic;
            setName("cardano");
            setPrivateKey(mn);
        } else if (coin === 'MATIC') {
            const mn = res.wallet.matic.mnemonic;
            setName("polygon");
            setPrivateKey(mn);
        }
        setStep1(false);
        setStep2(true)
    }




    const availableBalance = () => {
        const acct = accounts.filter(data => data.currency === coin);
        const data = acct[0];
        setAvailableBalance(data.balance.availableBalance)
        console.log("ddo", acct)
    }

    const handleAmount = (e) => {

        if (e.target.value > Number(aBalance)) {
            e.target.value = Number(aBalance);
            setAmount(e.target.value)
        } else {
            setAmount(e.target.value)
        }

    }




    return (
        <Card variant="outlined">
            <CardContent>
                <Grid container>
                    <Grid item xs={12} md={8}>
                        {
                            step1 && <>
                                <Box sx={{ mb: 4 }}>
                                    <Typography
                                        color="primary"
                                        fontWeight={"bold"}
                                        variant="subtitle2"
                                    >
                                        From {availableBalance}
                                    </Typography>
                                    <Autocomplete
                                        options={coins}
                                        onChange={handleChange}
                                        autoHighlight
                                        onInputChange={handleSelect}
                                        getOptionLabel={(option) => `${option.name}`}
                                        renderOption={(props, option) => (
                                            <Box
                                                component="li"
                                                sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                                                {...props}

                                            >
                                                <img
                                                    loading="lazy"
                                                    width="40"
                                                    src={option.image}
                                                    srcSet={option.image}
                                                    alt=""
                                                />
                                                <Box>
                                                    <Typography variant="body2">
                                                        {option.name}{" "}
                                                    </Typography>
                                                    <Typography variant="caption" sx={{ color: "#C0BFC2", }}>
                                                        0 {String(option.symbol).toUpperCase()}
                                                    </Typography>
                                                </Box>
                                            </Box>
                                        )}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}

                                                fullWidth

                                            />
                                        )}
                                    />
                                </Box>
                                <Box sx={{ mb: 4 }}>
                                    <Box
                                        display="flex"
                                        justifyContent={"space-between"}
                                        alignContent="center"
                                    >
                                        {" "}
                                        <Typography
                                            color="primary"
                                            fontWeight={"bold"}
                                            variant="subtitle2"

                                        >
                                            To
                                        </Typography>
                                        <Button size="small" onClick={() => setCurrentAddress(address)}>my wallet</Button>
                                    </Box>
                                    <TextField variant="outlined"

                                        onChange={(e) => setToAddress(e.target.value)}

                                        fullWidth
                                        InputProps={{
                                            endAdornment: <InputAdornment position="end">

                                                {startScan && (
                                                    <>
                                                        <QrReader
                                                            delay={delay}
                                                            style={previewStyle}
                                                            onError={handleError}
                                                            onScan={handleScan}
                                                        />

                                                    </>
                                                )}
                                                <IconButton size="small" onClick={() => {
                                                    setStartScan(!startScan);
                                                }}>
                                                    <QrCode2 color="primary" />


                                                </IconButton>
                                                <Button size="small" onClick={handlePasteAnywhere}>
                                                    <Typography variant="caption" sx={{ textTransform: "capitalize" }}>paste</Typography>
                                                </Button>
                                            </InputAdornment>,
                                        }}
                                    />

                                </Box>

                                <Box sx={{ mb: 4 }}>
                                    <Typography
                                        color="primary"
                                        fontWeight={"bold"}
                                        type="number"
                                        variant="subtitle2"

                                    >
                                        Amount
                                    </Typography>
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        onChange={handleAmount}
                                        type="number"
                                        InputProps={{ inputProps: { min: "0", max: "10", step: "1" } }}

                                    />
                                </Box>
                                <Box
                                    display="flex"
                                    alignContent={"center"}
                                    sx={{ mb: 1 }}
                                >
                                    <Typography
                                        sx={{ color: "#C0BFC2", mr: 2 }}
                                        variant="subtitle2"
                                    >
                                        Available:
                                    </Typography>
                                    <Typography color={"primary"} variant="subtitle2">
                                        {aBalance - amount} {fromSymbol}
                                    </Typography>
                                </Box>
                                {/* <Box
                                    display="flex"
                                    alignContent={"center"}
                                    sx={{ mb: 1 }}
                                >
                                    <Typography
                                        sx={{ color: "#C0BFC2", mr: 2 }}
                                        variant="subtitle2"
                                    >
                                        Network fee:
                                    </Typography>
                                    <Typography
                                        sx={{ color: "#C0BFC2", mr: 2 }}
                                        variant="subtitle2"
                                    >
                                        _
                                    </Typography>
                                </Box> */}
                                {/* <Box>
                                    <Button>Advanced options</Button>
                                </Box> */}
                                <Divider />
                                <Box display="flex" sx={{ justifyContent: 'end', mt: 2 }}>
                                    <Button variant="contained" size="large" onClick={handleNext}>Next</Button>
                                </Box>
                            </>
                        }
                        {
                            step2 && <>
                                <Box display="flex" flexDirection={"column"} justifyContent="center" alignItems={"center"}>
                                    <Typography variant="body2">Are you sure you want to</Typography>
                                    <Typography color="primary" sx={{ my: 2 }} fontWeight={"bold"} variant="h6">Send {amount} {fromSymbol}</Typography>
                                </Box>
                                <Divider />
                                <Box sx={{ mt: 2 }}>
                                    <StepTwoText prefixTitle={"You will send"} prefixSubtitle="equivelent" suffixTitle={amount} suffixSubtitle="0.00 USD" />
                                    <StepTwoText prefixTitle={"From Wallet"} prefixSubtitle="address" suffixTitle={fromCrypto} suffixSubtitle={from} />
                                    <StepTwoText prefixTitle={"To Address"} prefixSubtitle="" suffixTitle={toAdress} suffixSubtitle={toAdress} />
                                    {/* <StepTwoText prefixTitle={"Nework fee"} prefixSubtitle="equivelent" suffixTitle={`00 ${fromSymbol}`} suffixSubtitle="0.00 USD" /> */}
                                    <StepTwoText prefixTitle={"Will recieve"} prefixSubtitle="" suffixTitle={`00 ${fromSymbol}`} suffixSubtitle="" />
                                </Box>
                                <Divider />
                                <Box display="flex" alignItems={"center"} justifyContent="space-between" sx={{ my: 2 }}>
                                    <Box>
                                        <Typography variant="subtitle2"> step 2 of 3</Typography>
                                    </Box>
                                    <Box>
                                        <Button sx={{ mr: 2, borderBottom: ' 1px  solid #1665C0' }} onClick={() => (setStep1(true), setStep2(false))}>Back</Button>
                                        <Button size="large" variant="contained" onClick={confirm}>Confirm</Button>
                                    </Box>
                                </Box>
                            </>
                        }
                    </Grid>
                    <Grid item xs={12} md={5}></Grid>
                    {
                        step3 && <>
                            <Grid xs={12}>
                                <Box display={"flex"} justifyContent="center" flexDirection={"column"} alignContent="center" alignItems={"center"}>
                                    <CheckCircle color="success" sx={{ fontSize: 70 }} />
                                    <Typography fontWeight={"bold"} variant="body1" sx={{ my: 2 }}>Success</Typography>
                                    <Typography variant="subtitle1">Transaction was sent</Typography>
                                    <Box sx={{ width: 450, mt: 2, textAlign: 'center', border: '2px solid #EBEBEB', borderRadius: 1, py: 4 }}>
                                        <Typography fontWeight={"bold"}>
                                            {amount} {fromSymbol}
                                        </Typography>
                                        <Typography variant="subtitle2">
                                            
                                        </Typography>
                                    </Box>
                                    <Box sx={{ width: 450, mt: 2, border: '2px solid #EBEBEB', borderRadius: 1, py: 2, display: 'flex', alignItems: 'center', justifyContent: 'space-between', px: 1 }}>
                                        <Typography color="primary" >
                                            Hash
                                        </Typography>
                                        <Typography variant="subtitle2">
                                            {hash}
                                        </Typography>
                                        <Box display="flex">
                                            <FilterNone sx={{ mr: 1 }} />
                                            <Launch />
                                        </Box>
                                    </Box>
                                    <Typography color="primary" sx={{ my: 2 }}>Would you like to rate us?</Typography>
                                    <Box>
                                        <Rating
                                            color="primary"

                                            value={value}
                                            onChange={(event, newValue) => {
                                                setValue(newValue);
                                            }}
                                        />
                                    </Box>
                                    <Divider sx={{ my: 3, width: '100%' }} />
                                    <Box >
                                        <Button variant="outlined" sx={{ borderRadius: 2, }}>send another transaction</Button>
                                    </Box>
                                </Box>
                            </Grid>
                        </>
                    }
                </Grid>
            </CardContent>
        </Card>
    )
}