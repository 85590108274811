import { Apple, Brightness2, Google, Mode, WindowSharp } from "@mui/icons-material";
import { Alert, Button, Card, Grid, Snackbar, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { QRCode } from "react-qrcode-logo";
import { useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";
import "./style.css";
import { useHistory } from "react-router-dom";
import logo from "../../assets/logo.png";
import { signIn } from "../../Services/requests";
import Navbar from "../../Layout/Navbar/Navbar";
import { LoadingButton } from "@mui/lab";

export const Login = () => {
  const [emailClick, setEmailClick] = useState(true);
  const [phoneClick, setPhoneClick] = useState(false);
  const [phone, setPhone] = useState(null);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [msg, setMsg] = useState("");
  const [loader, setLoader] = useState(false);
  const [snackb, setSnackbar] = useState(false);
  const history = useHistory();

  const handleSubmit = async () => {
    setLoader(true);
    const data = {
      username: username,
      password: password
    }

    const res = await signIn(data);
    setLoader(false);
    if (res.status === 'success') {
      history.push("/");
      window.location.reload();
    } else {
      setMsg(res.message);
      setSnackbar(true)

    }

  }
  return (
    <>
      <Navbar />

      <Snackbar open={snackb} autoHideDuration={6000} >
        <Alert severity="warning" sx={{ width: '100%' }}>
          {msg}
        </Alert>
      </Snackbar>
      <Box
        sx={{ minHeight: "100vh", backgroundColor: 'primary.body' }}
        display="flex"
        alignItems={"center"}
        flexDirection={"column"}
      >

        <Grid container alignItems={"center"} className="bodys">
          <Grid item xs={12} md={12}>
            <Box display={"flex"} justifyContent="center">
              <Box sx={{ maxWidth: 500, mt: 4 }} display="flex" flexDirection={"column"}>
                <Card sx={{ p: 4 }} variant="outlined">
                  <Typography fontWeight={"bold"} variant="h6">
                  Pesa Wyse Account Login
                  </Typography>
                  <Box
                    display="flex"
                    flexDirection={"column"}
                    alignItems="start"
                    sx={{ mt: 4 }}
                  >
                    <Typography>username</Typography>
                    <TextField fullWidth  onChange={(e) => setUsername(e.target.value)} />
                  </Box>


                  <Box sx={{ mt: 4 }}>
                    <Typography>Password</Typography>
                    <TextField fullWidth type={"password"} onChange={(e) => setPassword(e.target.value)} />
                  </Box>
                  <Box width={500}>

                  </Box>
                  <Box sx={{ mt: 4 }}>
                    {
                      loader ? (
                        <LoadingButton fullWidth loading={loader}
                          loadingIndicator="Loading…"
                          variant="outlined">loading</LoadingButton>
                      ) : (<Button variant="contained" fullWidth size="large" onClick={handleSubmit}>
                        Log In
                      </Button>)
                    }
                  </Box>

                  <Box sx={{ mt: 5 }}>
                    <Typography>Don't have account? <Button onClick={() => history.push("/register")}>Register</Button> </Typography>
                  </Box>

                </Card>
              </Box>
            </Box>
          </Grid>

        </Grid>
      </Box>
    </>
  );
};
