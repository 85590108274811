import { ArticleOutlined } from "@mui/icons-material"
import { Autocomplete, Button, Card, CardContent, Divider, Grid, List, ListItem, ListItemText, makeStyles, TextField, Typography } from "@mui/material"
import { Box } from "@mui/system"

const types = [
    "All",
    "Recieved",
    "Send",
    "Exchange",
    "Buy",
    "Multisigs"


]

export const History = ({ coins, transactions }) => {


    return (
        <Card variant="outlined" sx={{ minHeight: 500, }}>
            <CardContent sx={{ pt: 0, mt: 0 }}>

                <Divider />
                <Grid container >
                    <Grid item xs={3} >

                        <Autocomplete
                            freeSolo
                            disableClearable

                            options={coins}
                            autoHighlight
                            style={{
                                minWidth: 300,
                                marginLeft: -14,


                            }}
                            getOptionLabel={(option) => `${option.name}`}
                            renderOption={(props, option) => (
                                <Box
                                    component="li"
                                    sx={{ "& > img": { mr: 2, flexShrink: 0 }, }}
                                    {...props}
                                >
                                    <img
                                        loading="lazy"
                                        width="40"
                                        src={option.image}
                                        srcSet={option.image}
                                        alt=""
                                    />
                                    <Box>
                                        <Typography variant="body2">
                                            {option.name}{" "}
                                        </Typography>
                                        <Typography variant="caption" sx={{ color: "#C0BFC2", }}>
                                            0 {String(option.symbol).toUpperCase()}
                                        </Typography>
                                    </Box>
                                </Box>
                            )}
                            renderInput={(params) => (
                                <TextField
                                    {...params}

                                    fullWidth
                                    variant="standard"
                                    placeholder="Wallet"
                                    inputProps={{
                                        ...params.inputProps,

                                    }}

                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={3} className="border">
                        <TextField fullWidth variant="standard"
                            select



                        >
                            {types.map((option) => (


                                <>
                                    <ListItem sx={{ p: 1 }}>
                                        <ListItemText>
                                            <Typography variant="subtitle2">{option}</Typography>
                                        </ListItemText>
                                    </ListItem>
                                    <Divider />
                                </>

                            ))}
                        </TextField>
                    </Grid>
                    <Grid item xs={6} className="border">
                        <TextField variant="standard" fullWidth />
                    </Grid>
                </Grid>
                <Divider />
                {
                    transactions.length === 0 ? (<Box sx={{ mt: 15 }}>
                        <Box display="flex" justifyContent="center" sx={{ mb: 3 }}>
                            <img src="https://guarda.co/app/b4167d4ba0df7168b2d1ed1690751cf6.svg" width="180px" />
                        </Box>
                        <Box display="flex" justifyContent="center" flexDirection={"column"}  >
                            <Typography variant="body2" textAlign={"center"}>
                                Top up your wallet or{" "}
                                <span color="primary">share your public address</span>{" "}


                            </Typography>
                            <Typography variant="body2" textAlign={"center"}> with somebody to get coins or tokens</Typography>
                        </Box>
                        <Box display="flex" justifyContent="center" sx={{ my: 4 }}>
                            <Button variant="contained" size="large">Buy Crypto</Button>
                        </Box>
                        <Box display="flex" justifyContent="center">
                            <Button>Load Transaction</Button>
                        </Box>
                    </Box>) : (
                        <Box sx={{ mt: 3 }} >
                            {
                                transactions.map((item) => (
                                    <Box display={"flex"} alignItems="center" justifyContent={"space-between"} sx={{ mb: 2 }}>
                                        <Typography variant="body2" fontWeight={"bold"} >{item.operationType}</Typography>
                                        <Typography variant="body2" sx={{ color: item.operationType === 'DEPOSIT' ? '#66bb6a' : '#dd2c00' }}  >{item.amount} {item.currency}</Typography>
                                    </Box>
                                ))
                            }

                        </Box>
                    )
                }
            </CardContent>
        </Card>
    )
}