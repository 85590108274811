import { Box } from '@mui/system';
import TradeViewChart from 'react-crypto-chart';


export const Charts = ({ coin }) => {
    const pair = `${coin}USDT`
    return (

        <Box sx={{height: "300px"}}>
            <TradeViewChart
                containerStyle={{
                    minHeight: '300px',
                    minWidth: '400px',
                    marginBottom: '30px',
                }}
                pair={pair}

            />
        </Box>
    )
}