import axios from "axios"

const TATUM_API_KEY = "855e8878-0896-4fc0-b3ea-8ec77d32afd0";
const tatumUrl = "https://api-eu1.tatum.io/v3"

// const baseUrl = "http://localhost:9000/api/v1";
const baseUrl = "https://exchanges.onrender.com/api/v1";

const customerId = localStorage.getItem("customerId")


export const signUp = async (data) => {
    return await axios.post(baseUrl + "/auth/register", data).then((res) => {
        const data = res.data;
        let customerId = data.savedUser.customerId;
        localStorage.setItem("isAuthenticated", true);
        localStorage.setItem("customerId", customerId)
        return data;
    }).catch((err) => { return err.response?.data })
}

export const signIn = async (data) => {
    return await axios.post(baseUrl + "/auth/login", data).then((res) => {
        localStorage.clear();
        const data = res.data;
        let customerId = data.payload.user.customerId;
        localStorage.setItem("isAuthenticated", true);
        localStorage.setItem("customerId", customerId);
        return data
    }).catch((err) => { return err.response.data })
}

export const getUserAccounts = async () => {
    return await axios.get(`https://api.tatum.io/v3/ledger/account/customer/${customerId}?pageSize=50`, {
        headers: {
            "x-api-key": "855e8878-0896-4fc0-b3ea-8ec77d32afd0"
        }
    }).then((res) => {
        return res.data
    }).catch((err) => { return err.response.data })
}

export const getUserWallet = async () => {
    return await axios.get(baseUrl + "/user/" + customerId).then((res) => {
        return res.data.payload
    }).catch((err) => { return err.response.data })
}

export const genPkey = async (data) => {
    return await axios.post(baseUrl + "/exchange/generateprivatekey", data).then((res) => {
        return res.data.payload
    }).catch((err) => { return err.response.data })
}

export const transferToBlockchain = async (data, name) => {
    return await axios.post(`${tatumUrl}/offchain/${name}/transfer`, {
        headers: {
            "x-api-key": "855e8878-0896-4fc0-b3ea-8ec77d32afd0"
        },
        body: JSON.stringify(data)
    }).then((res) => {
        return res.data
    }).catch((err) => { return err.response.data })
}

export const generateDepositeAdress = async (id) => {
    return await axios.post(baseUrl + "/auth/generateaddress/" + id).then((res) => {
        return res.data.address
    }).catch((err) => { return err.response.data })
}

export const getDepositeAdress = async (id) => {
    return await axios.get(`https://api-eu1.tatum.io/v3/offchain/account/${id}/address`, {
        headers: {
            "x-api-key": TATUM_API_KEY
        }
    }).then((res) => {
        return res;
    }).catch((err) => { return err.response.data })
}

export const userTransacctions = async () => {
    // const data = {
    //     id: customerId
    // }
    // const config = {
    //     body: data,
    //     headers: {
    //         'Content-Type': 'application/json',
    //         'x-api-key': '1d47867f-609c-4f08-a09c-b6a3c803adc1'
    //     }
    // };

    // return await axios.post("https://api.tatum.io/v3/ledger/transaction/customer?pageSize=50", config).then((res) => {
    //     return res;
    // }).catch((err) => { return err.response.data })

    let myHeaders = new Headers();
    myHeaders.append("x-api-key", "1d47867f-609c-4f08-a09c-b6a3c803adc1");
    myHeaders.append("Content-Type", "application/json");

    let raw = JSON.stringify({
        "id": customerId
    });

    let requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
    };

    return await fetch("https://api.tatum.io/v3/ledger/transaction/customer?pageSize=50", requestOptions)
        .then(response => response.json())
        .then(result => { return result })
        .catch(error => { return error });
}

export const exchangeCrypto = async (data) => {
    return await axios.post(`${tatumUrl}/trade`, {
        headers: {
            "x-api-key": "855e8878-0896-4fc0-b3ea-8ec77d32afd0"
        },
        body: JSON.stringify(data)
    }).then((res) => {
        return res.data;
    }).catch((err) => { return err.response.data })
}
export const createWallet = async (data) => {
    return await axios.post(baseUrl + "/wallet/createwallet", data).then((res) => {
        return res.data.payload
    }).catch((err) => {
        console.log("err>>", err)
    })


}

export const transaction = async (data) => {
    return await axios.post(baseUrl + "/exchange/transaction", data).then((res) => {
        return res.data.payload
    }).catch((err) => {
        console.log("err>>", err)
    })


}

export const getBtcAddress = async (data) => {
    return await axios.post(baseUrl + "/wallet/btcaccount", data).then((res) => {
        return res.data.payload
    }).catch((err) => {
        console.log("err>>", err)
    })


}

export const getEthAddress = async (data) => {
    return await axios.post(baseUrl + "/wallet/ethaccount", data).then((res) => {
        return res.data.payload
    }).catch((err) => {
        console.log("err>>", err)
    })


}
export const downloadBackup = async () => {
    await axios.get(baseUrl + "/auth/download").then((res) => {
        console.log(res)
    }).catch((err) => {
        console.log(err)
    })

}