



import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { Link, useHistory } from 'react-router-dom'
import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import  { tableCellClasses } from "@mui/material/TableCell";




const columns = [
    { id: "pr", label: "Name" },
    { id: "Coin", label: "Last Price" },
    {
      id: "24h Change",
      label: "24h Change",
    },
    {
      id: "Market Cap",
      label: "Market Cap",
    },
  ];

const Coins = (props) => {


  
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
  
    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };
  
    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
    };

    const history = useHistory();
    return (

        <Paper sx={{ width: "100%", overflow: "hidden" }} elevation={0}>
     
      <TableContainer  style={{ maxHeight: 350 }}>
        <Table  aria-label="sticky table" sx={{
    [`& .${tableCellClasses.root}`]: {
      borderBottom: "none"
    }
  }}>
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell key={column.id} align={column.align} sx={{color: "#BDC1C9"}}>
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {props.coins.map(coins => (

              <TableRow
              hover
              onClick={()=> history.push("/coin/" + coins.id)}
               
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
               <TableCell  component="th" scope="row">
                 <Box display="flex" alignItems={"center"}>
                 <img  src={coins.image} alt="image" width={"30px"} />
                 <Typography sx={{ml:2}} fontWeight={"600"} fontSize="15px">{coins.name}</Typography>
                 <Typography sx={{ml:2}} fontSize="12px">{coins.symbol.toUpperCase()}</Typography>
                 </Box>
                </TableCell>
                
                <TableCell  sx={{  }}>
                 $ {coins.current_price.toLocaleString()}
                </TableCell>
                <TableCell  sx={{  }} style={{color: Math.sign(coins.price_change_percentage_24h) === -1 ? "#CF304A" : "#7BCBAB"}}>
                  {coins.price_change_percentage_24h.toFixed(2)}%
                </TableCell>
                
                <TableCell  sx={{  }}>
                 $ {coins.market_cap.toLocaleString()}
                </TableCell>
              </TableRow>
              
          
            ))}
          </TableBody>
        </Table>
      </TableContainer>
     
    </Paper>
        
    )
}

export default Coins