import { configureStore } from '@reduxjs/toolkit';

import { cryptoApi } from '../Services/crypto';
import { themeReducer } from '../Services/ThemeReducer';

export default configureStore({
  reducer: {
    status:themeReducer,
    [cryptoApi.reducerPath]: cryptoApi.reducer,
  },



});