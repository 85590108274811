import {
  Autocomplete,
  Button,
  Card,
  CardContent,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { Hero } from "../../Components/Hero/Hero";
import Navbar from "../../Layout/Navbar/Navbar";
import logo from "../../assets/logo.svg";
import "./style.css";
import { createRef, useEffect, useState } from "react";
import axios from "axios";
import Coins from "../../Components/Currencies/Coins";
import {
  AccountBox,
  Backpack,
  Book,
  Forum,
  Help,
  HelpSharp,
  KeyboardArrowRight,
  Person,
  Search,
  Square,
} from "@mui/icons-material";
import currency from "../../assets/currency.svg";
import { Footer } from "../../Layout/Footer/Index";

import { useGetCryptosQuery } from "../../Services/crypto";

const needHelp = [
  {
    title: "24/7 Chat Support",
    subtitle:
      "Get 24/7 chat support with our friendly customer service agents at your service.",
    action: "Chat now",
    icon: <Person sx={{ fontSize: "130px" }} color="primary" />,
  },
  {
    title: "FAQs",
    subtitle: "View FAQs for detailed instructions on specific features.",
    action: "Learn more",
    icon: <Forum sx={{ fontSize: "130px" }} color="primary" />,
  },
  {
    title: "Blog",
    subtitle: "Stay up to date with the latest stories and commentary.",
    action: "Learn more",
    icon: <Book sx={{ fontSize: "130px" }} color="primary" />,
  },

  {
    title: "mooncash",
    subtitle: "solana cream the brave",
    action: "Explore"
  }
];

const trusted = [
  {
    icon: <Square sx={{ fontSize: "100px" }} />,
    title: "Secure Asset Fund for Users (SAFU)",
    subtitle:
      "Binance stores 10% of all trading fees in a secure asset fund to protect a share of user funds. ",
  },
  {
    icon: <Square sx={{ fontSize: "100px" }} />,
    title: "Personalised Access Control ",
    subtitle:
      "Advanced access control allows you to restrict devices and addresses that can access your account, for greater ease of mind.",
  },
  {
    icon: <Square sx={{ fontSize: "100px" }} />,
    title: "Advanced Data Encryption",
    subtitle:
      "Your transaction data is secured via end-to-end encryption, ensuring that only you have access to your personal information.",
  },
];

export const Home = ({ simplified }) => {
  const [coins, setCoins] = useState([]);
  const [value, setValue] = useState();
  const [inputValue, setInputValue] = useState('');
 
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
 
  }, []);

  const url =
    "https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&order=market_cap_desc&per_page=50&page=1&sparkline=false";

  useEffect(() => {
    axios
      .get(url)
      .then((response) => {
        setCoins(response.data);
       
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  return (
    <>
      <Navbar />
      <div className="main">
        <Hero />

        <Grid
          container
          sx={{ mt: 8 }}
          display="flex"
          justifyContent="center"
          alignItems={"center"}
          className="bodys"
        >
          <Grid xs={12} md={6}>
            <Typography fontSize={"25px"} fontWeight={"bold"}>
              New: Trade Bitcoin for free
            </Typography>
            <Typography>
              0 trading fee on selected Pesa Wyse spot trading pairs now
            </Typography>
            <Button sx={{ mt: 3, px: 6 }} size="large" variant="contained">
              Learn More
            </Button>
          </Grid>
          <Grid xs={12} md={6}>
            <Box display="flex" justifyContent={"center"} alignItems="center">
              <img src={logo} alt="image" width={"400px"} />
            </Box>
          </Grid>
        </Grid>
        <Box className="bodys">
          <Grid container sx={{ mt: 8 }}>
            <Grid item xs={12} md={6}>
              <Typography variant="h4" fontWeight={"bold"}>
               Popular Cryptocurrencies
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
          

              <Box className="endtext" sx={{display: { xs: 'none', md: 'block', }}} >

              <Button endIcon={<KeyboardArrowRight />} sx={{color: "#757575", textTransform: "capitalize"}}>
                view on market
              </Button>

                  
              </Box>
            </Grid>
          </Grid>
        </Box>

        <Grid container sx={{ mt: 5 }} >
          
          
         <Grid item xs={12} className="bodys">
         <Coins coins={coins} />
         </Grid>
        </Grid>

        

        <Box
          display={"flex"}
          flexDirection="column"
          alignContent={"center"}
          alignItems="center"
          justifyContent="center"
          sx={{ minHeight: 300, width: "100%", mt: 5, px:2, py:4 }}
          className="bg"
        >
          <Typography fontWeight={"bold"} variant="h4">
            The Only Crypto Wallet You Need
          </Typography>
          <Box sx={{ maxWidth: 800 }}>
            <Typography sx={{ mt: 5, }} textAlign="center">
            Pesa Wyse is an ultimate solution for modern crypto management. You can
              do it all from the comfort of one single app. Earn with staking,
              Purchase, Instantly Exchange at best rates, get crypto loans, add
              extra security layer with Multisignature, and the list goes on.
            </Typography>
          </Box>

          <a href="/register">
            {" "}
            <Button variant="contained" size="large" sx={{ px: 4, mt: 3 }}>
              Sign Up Now
            </Button>
          </a>
        </Box>
      </div>
      <Footer />
    </>
  );
};
