import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import AdbIcon from '@mui/icons-material/Adb';
import logo from "../../assets/logo.png"
import { Home, Mode } from '@mui/icons-material';
import { useHistory } from 'react-router-dom';
import { FormControlLabel, Switch } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useSelector, useDispatch } from "react-redux";
import { Light, Dark } from '../../actions/themeAction';
import { useEffect } from 'react';
const pages = ['Wallet', 'Buy & Sell', 'Earn', 'Setting'];
const settings = ['Profile', 'Account', 'Dashboard', 'Logout'];


const MaterialUISwitch = styled(Switch)(({ theme }) => ({
    width: 62,
    height: 34,
    padding: 7,
    '& .MuiSwitch-switchBase': {
        margin: 1,
        padding: 0,
        transform: 'translateX(6px)',
        '&.Mui-checked': {
            color: '#fff',
            transform: 'translateX(22px)',
            '& .MuiSwitch-thumb:before': {
                backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
                    '#fff',
                )}" d="M4.2 2.5l-.7 1.8-1.8.7 1.8.7.7 1.8.6-1.8L6.7 5l-1.9-.7-.6-1.8zm15 8.3a6.7 6.7 0 11-6.6-6.6 5.8 5.8 0 006.6 6.6z"/></svg>')`,
            },
            '& + .MuiSwitch-track': {
                opacity: 1,
                backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
            },
        },
    },
    '& .MuiSwitch-thumb': {
        backgroundColor: theme.palette.mode === 'dark' ? '#003892' : '#001e3c',
        width: 32,
        height: 32,
        '&:before': {
            content: "''",
            position: 'absolute',
            width: '100%',
            height: '100%',
            left: 0,
            top: 0,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
                '#fff',
            )}" d="M9.305 1.667V3.75h1.389V1.667h-1.39zm-4.707 1.95l-.982.982L5.09 6.072l.982-.982-1.473-1.473zm10.802 0L13.927 5.09l.982.982 1.473-1.473-.982-.982zM10 5.139a4.872 4.872 0 00-4.862 4.86A4.872 4.872 0 0010 14.862 4.872 4.872 0 0014.86 10 4.872 4.872 0 0010 5.139zm0 1.389A3.462 3.462 0 0113.471 10a3.462 3.462 0 01-3.473 3.472A3.462 3.462 0 016.527 10 3.462 3.462 0 0110 6.528zM1.665 9.305v1.39h2.083v-1.39H1.666zm14.583 0v1.39h2.084v-1.39h-2.084zM5.09 13.928L3.616 15.4l.982.982 1.473-1.473-.982-.982zm9.82 0l-.982.982 1.473 1.473.982-.982-1.473-1.473zM9.305 16.25v2.083h1.389V16.25h-1.39z"/></svg>')`,
        },
    },
    '& .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
        borderRadius: 20 / 2,
    },
}));

const AppNav = ({ firstItem, }) => {
    const [anchorElNav, setAnchorElNav] = React.useState(null);
    const [anchorElUser, setAnchorElUser] = React.useState(null);
    const state = useSelector((state) => state);
    const dispatch = useDispatch();
    const mode = useSelector((state) => state.status.status);

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };


    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };



    const history = useHistory();

    useEffect(() => {
        setTimeout(() => {
            console.log("jjjj", mode)

        }, 3000);
    }, []);

    const handleToggle = (e) => {

        if (mode === true) {
            dispatch(Light())
        } else {
            dispatch(Dark());

        }

        setTimeout(() => {
            console.log(mode)

        }, 2000);





    }


    return (
        <AppBar position="static" sx={{ bgcolor: 'primary.bg' }} elevation={0}>
            <Container maxWidth="lg">
                <Toolbar disableGutters >
                    <img src={logo} width={"70px"} onClick={() => history.push("/")} />

                    <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>

                    </Box>
                    <Typography
                        variant="h5"
                        noWrap
                        component="a"
                        href=""
                        sx={{
                            mr: 2,
                            display: { xs: 'flex', md: 'none' },
                            flexGrow: 1,
                            fontFamily: 'monospace',
                            fontWeight: 700,
                            letterSpacing: '.3rem',
                            color: 'inherit',
                            textDecoration: 'none',
                        }}
                    >
                        LOGO
                    </Typography>
                    <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' }, alignItems: 'center' }}>
                        {firstItem.map((page, idx) => (
                            <Button
                                key={idx}
                                onClick={() => history.push(page.to)}
                                sx={{ my: 2, mr: 2, color: "#929FA4", display: 'block' }}
                            >
                                {page.name}
                            </Button>
                        ))}
                        <Box>
                        
                            <FormControlLabel
                                control={<MaterialUISwitch sx={{ m: 1 }} onChange={handleToggle} checked={mode} />}
                                label=""
                            />

                          
                        </Box>

                    </Box>

                    <Box sx={{ flexGrow: 0, }}>

                        <Box sx={{ display: { xs: 'none', md: 'block', } }}>

                            <Button onClick={() => history.push("/")} size='large' startIcon={<Home sx={{ color: "#F1F2FA", }} />} variant="contained" sx={{
                                bgcolor: "primary.bg", color: "#455A64", '&:hover': {
                                    backgroundColor: '#fff',
                                    color: "#000",


                                },

                            }}>
                                {mode}   Back to Pesa Wyse

                            </Button>
                        </Box>

                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            sx={{ display: { xs: 'block', md: 'none', } }}
                            onClick={handleOpenNavMenu}

                        >
                            <MenuIcon />
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorElNav}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            open={Boolean(anchorElNav)}
                            onClose={handleCloseNavMenu}
                            sx={{
                                display: { xs: 'block', md: 'none' },
                            }}
                        >
                            {firstItem.map((page, idx) => (
                                <MenuItem key={idx} onClick={handleCloseNavMenu}>
                                    <Typography textAlign="center">{page.name}</Typography>
                                </MenuItem>
                            ))}
                        </Menu>



                    </Box>
                </Toolbar>
            </Container>
        </AppBar>
    );
};
export default AppNav;
