import {
    Button,
    Container,
    Typography,
    Avatar,
    ListItem,
    List,
    ListItemText,
    Divider,
    Badge,
} from "@mui/material";
import { Box } from "@mui/system";
import { useState } from "react";
import AppNav from "../../Layout/Appbar/Index";

const alert = [
    // {
    //     title: "Successful Payment",
    //     subtitle: "Your payment for plumbing service was successful",
    //     dot: true,
    //     time: "5 : 45 PM",
    // },
    // {
    //     title: "Cancelled Service",
    //     subtitle: "The Service Seeker  cancelled the booking ",
    //     dot: true,
    //     time: "11 : 12 AM",
    // },
    // {
    //     title: "mindinglyfe Points",
    //     subtitle: "You earned 1 mindinglyfe points",
    //     time: "10 : 45 AM",
    // },
    // {
    //     title: "Seekers near you",
    //     subtitle:
    //         "There are lots of seekers for rides near you, come online and get hired",
    //     time: "8 : 06 AM",
    // },
];
const firstItem = [
    {
        name: "Wallets",
        to: "wallets",
    },
    {
        name: "Buy & Sell",
        to: "buysell",
    },
    {
        name: "Earn",
        to: "earn",
    },
    {
        name: "Setting",
        to: "setting",
    },
];
export const Notifications = () => {
    return (
        <>

            <AppNav firstItem={firstItem} />

            <Box className="e7" sx={{ backgroundColor: 'primary.body' }}>
                <Container sx={{ pt: 4 }}>


                    <Box display="flex" justifyContent={"center"} mt={0}>
                        <Box
                            width={"870px"}
                            bgcolor="#FFFFFF"
                            px={8}
                            py={10}
                            minHeight="75vh"
                            boxShadow={0}
                        >
                            <List>
                                {alert.map((item) => (
                                    <>
                                        <ListItem
                                            key={item.title}
                                            secondaryAction={
                                                <Box
                                                    display={"flex"}
                                                    flexDirection="column"
                                                    alignItems={"end"}
                                                >
                                                    {item.dot ? (
                                                        <Box
                                                            sx={{
                                                                width: "15px",
                                                                height: "15px",
                                                                backgroundColor: "#F32424",
                                                                borderRadius: "30px",
                                                                mb: 1,
                                                            }}
                                                        />
                                                    ) : null}

                                                    <Typography variant="caption">{item.time}</Typography>
                                                </Box>
                                            }
                                        >
                                            <ListItemText
                                                primary={
                                                    <Typography
                                                        my={1}
                                                        sx={{ color: item.dot ? "#F32424" : "initial" }}
                                                    >
                                                        {item.title}
                                                    </Typography>
                                                }
                                                secondary={item.subtitle}
                                            />
                                        </ListItem>
                                        <Divider component={"li"} />
                                    </>
                                ))}
                            </List>
                        </Box>
                    </Box>
                </Container>
            </Box>
        </>
    );
};
