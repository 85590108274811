
import { Box } from "@mui/system";
import AppNav from "../../Layout/Appbar/Index";
import "./style.css";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import React, { useState } from "react";
import { CreateWalletPassword } from "../../Components/WalletPassword/Index";
import { CreateWalletProgress } from "../../Components/Core/createWalletProgress";
import { createWallet, } from "../../Services/requests";
import bcrypt from 'bcryptjs'
import { Backup } from "../../Components/DownloadBackup/Index";

export default function CreateWallet() {
  const [walletPassword, setWalletPassword] = useState(true);
  const [createWalletProgress, setCreateWalletProgress] = useState(false);
  const [backups, setBackups] = useState();
  const [downloadbackup, setDownloadbackup] = useState(false);
  const [password, setPassword] = useState("");
  const [done, setDone] = useState(false);
  const salt = bcrypt.genSaltSync(10)




  const handleSubmit = async (password) => {
    const hashedPassword = bcrypt.hashSync(
      password, '$2a$10$CwTycUXWue0Thq9StjUM0u');
    const data = {
      password: hashedPassword
    }
    setWalletPassword(false);
    const wallets = await createWallet(data);
    let dt = [
      wallets.password,
      wallets.wallets[0].account.privateKey,
      wallets.wallets[1].account.privateKey,

    ]
    setBackups(dt);
    setCreateWalletProgress(true);
    setTimeout(() => {
      setCreateWalletProgress(false);
      setDownloadbackup(true);

    }, 3000);


  };
  const dBackup = () => {
    downloadTxtFile(backups)
  }
  const downloadTxtFile = (wallet) => {
    const element = document.createElement("a");
    const file = new Blob([wallet], { type: 'text/plain' });
    element.href = URL.createObjectURL(file);
    element.download = "backup.txt";
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  }


  const firstItem = [{
    name: "Create Wallet",
    to: "createwallet"
  }, {
    name: "Restore or Import",
    to: "restorewallet"
  }]

  return (
    <div className="">
      <AppNav firstItem={firstItem} />
      <h1>{password}</h1>
      <Box className="e7" sx={{backgroundColor: 'primary.body'}}>

        {walletPassword ? <CreateWalletPassword submit={handleSubmit} /> : null}
        {createWalletProgress ? <CreateWalletProgress isDone={done} /> : null}
        {downloadbackup ? <Backup download={dBackup} /> : null}

      </Box>
    </div>
  );
}
