import { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
const Protected = ({ children }) => {
  const islogin = JSON.parse(
    localStorage.getItem("isAuthenticated") || "false"
  );

  if (!islogin) {
    return <Redirect to="/home" replace />;
  }
  return children;
};
export default Protected;
