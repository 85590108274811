import React from "react";
import {
    Button,
    Card,
    CardContent,
    Divider,
    FormControl,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { useState } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import { Home, QrCode, QrCode2 } from "@mui/icons-material";
import QrReader from 'react-qr-scanner'
import { exchangeCrypto, generateDepositeAdress, getDepositeAdress } from "../../Services/requests";


export const Exchange = ({ coins, selectedIdx, address, coin, accounts, xpub, accountId }) => {


    const [fromCrypto, setFromCrypto] = useState("");
    const [fromSymbol, setFromSymbol] = useState("");
    const [fromAddress, setFromAddress] = useState("");
    const [toCrypto, setToCrypto] = useState("");
    const [toSymbol, setToSymbol] = useState("");
    const [toAddress, setToAddress] = useState("");
    const [amount, setAmount] = useState("");
    const [price, setPrice] = useState("");
    const [type, setType] = useState('BUY');

    const handleChange = (event) => {
        setType(event.target.value);
    };


    const handleFrom = async (props, option) => {
        const coin = coins.filter((e) => e.name === option);
        setFromSymbol(coin[0].symbol.toUpperCase());
        setFromCrypto(coin[0].name.toUpperCase())
        const acct = accounts.filter((e) => e.currency === coin[0].symbol.toUpperCase());
        const res = await getDepositeAdress(acct[0].id);
        console.log("think", acct);
        if (res.length > 0) {
            setFromAddress(res[0].address);
        } else {
            const data = await generateDepositeAdress(acct[0].id);
            setFromAddress(data);
        }

    }

    const handleTo = async (props, option) => {
        const coin = coins.filter((e) => e.name === option);
        setToSymbol(coin[0].symbol.toUpperCase());
        setToCrypto(coin[0].name.toUpperCase())
        const acct = accounts.filter((e) => e.currency === coin[0].symbol.toUpperCase());
        const res = await getDepositeAdress(acct[0].id);
        console.log("think", acct);
        if (res.length > 0) {
            setToAddress(res[0].address);
        } else {
            const data = await generateDepositeAdress(acct[0].id);
            setToAddress(data);
        }

    }

    const submit = async () => {

        const data = {
            "type": type,
            "amount": amount,
            "price": price,
            "pair": fromSymbol + "/" + toSymbol,
            "currency1AccountId": fromAddress,
            "currency2AccountId": toAddress,
        }

        await exchangeCrypto(data)

    }


    return (
        <Card>
            <CardContent>
                <Grid container>
                    <Grid item xs={12} md={8}>
                        <Box sx={{ mb: 4 }}>
                            <Typography
                                color="primary"
                                fontWeight={"bold"}
                                sx={{ mb: 1 }}
                                variant="subtitle2"
                            >
                                Type
                            </Typography>

                            <FormControl fullWidth>

                                <Select

                                    value={type}

                                    onChange={handleChange}
                                >
                                    <MenuItem value={"BUY"}>Buy</MenuItem>
                                    <MenuItem value={"SELL"}>Sell</MenuItem>
                                </Select>
                            </FormControl>

                        </Box>
                        <Box sx={{ mb: 4 }}>
                            <Typography
                                color="primary"
                                fontWeight={"bold"}
                                variant="subtitle2"
                            >
                                From
                            </Typography>
                            <Autocomplete
                                options={coins}
                                onInputChange={handleFrom}

                                autoHighlight
                                getOptionLabel={(option) => `${option.name}`}
                                renderOption={(props, option) => (
                                    <Box
                                        component="li"
                                        sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                                        {...props}
                                    >
                                        <img
                                            loading="lazy"
                                            width="40"
                                            src={option.image}
                                            srcSet={option.image}
                                            alt=""
                                        />
                                        <Box>
                                            <Typography variant="body2">
                                                {option.name}{" "}
                                            </Typography>
                                            <Typography variant="caption" sx={{ color: "#C0BFC2", }}>
                                                0 {String(option.symbol).toUpperCase()}
                                            </Typography>
                                        </Box>
                                    </Box>
                                )}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}

                                        fullWidth
                                        inputProps={{
                                            ...params.inputProps,
                                            autoComplete: "new-password",
                                        }}
                                    />
                                )}
                            />
                        </Box>

                        <Box sx={{ mb: 4 }}>
                            <Typography
                                color="primary"
                                fontWeight={"bold"}
                                variant="subtitle2"
                            >
                                To
                            </Typography>
                            <Autocomplete

                                options={coins}
                                autoHighlight
                                onInputChange={handleTo}
                                getOptionLabel={(option) => `${option.name}`}
                                renderOption={(props, option) => (
                                    <Box
                                        component="li"
                                        sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                                        {...props}
                                    >
                                        <img
                                            loading="lazy"
                                            width="40"
                                            src={option.image}
                                            srcSet={option.image}
                                            alt=""
                                        />
                                        <Box>
                                            <Typography variant="body2">
                                                {option.name}{" "}
                                            </Typography>
                                            <Typography variant="caption" sx={{ color: "#C0BFC2", }}>
                                                0 {String(option.symbol).toUpperCase()}
                                            </Typography>
                                        </Box>
                                    </Box>
                                )}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}

                                        fullWidth
                                        inputProps={{
                                            ...params.inputProps,
                                            autoComplete: "new-password",
                                        }}
                                    />
                                )}
                            />
                        </Box>

                        <Box sx={{ mb: 4 }}>
                            <Typography
                                color="primary"
                                fontWeight={"bold"}
                                variant="subtitle2"
                            >
                                Amount
                            </Typography>
                            <TextField
                                variant="outlined"
                                fullWidth
                                onChange={(e) => setAmount(e.target.value)}
                                type={"number"}
                            />
                        </Box>
                        <Box sx={{ mb: 4 }}>
                            <Typography
                                color="primary"
                                fontWeight={"bold"}
                                variant="subtitle2"
                            >
                                Price
                            </Typography>
                            <TextField
                                variant="outlined"
                                fullWidth
                                onChange={(e) => setPrice(e.target.value)}
                                type={"number"}
                            />
                        </Box>



                        <Divider />
                        <Box display="flex" sx={{ justifyContent: 'end', mt: 2 }}>
                            <Button variant="contained" size="large" onClick={submit}>Next</Button>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={4}></Grid>
                </Grid>
            </CardContent>
        </Card>
    )
}