import { ArrowBackIos, ArrowLeft } from "@mui/icons-material";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import AppNav from "../../Layout/Appbar/Index";
import "./style.css";

import React from "react";
import { useHistory } from "react-router-dom";

export default function E7({handleToggle, isOn}) {

    const history = useHistory();
    const firstItem = [{
      name: "Create Wallet",
      to: "createwallet"
  }, {
      name: "Restore or Import",
      to: "restorewallet"
  }]
  
  return (
    <div className="">
      <AppNav firstItem={firstItem} isOn={isOn} handleToggle={handleToggle} />
      <div className="e7">
        <Grid container sx={{ pt: 5 }} className='bodys'>
          <Grid item xs={12}>
            <Box display={"flex"} justifyContent={"center"}>
              <Card
                sx={{ width: 600, borderRadius: "0px" }}
                elevation={0}
                raised
              >
                <CardContent sx={{ p: 5 }}>
                  <Typography
                    variant="h5"
                    color={"primary"}
                    textAlign={"center"}
                    fontWeight="bold"
                  >
                    Hello! Welcome to Pesa Wyse Wallet
                  </Typography>
                  <Box sx={{ mt: 4 }}>
                    <Typography variant="body2">
                      This wallet makes it easy to access your crypto and
                      interact with blockchain. Pesa Wyse does not have access to
                      your funds.
                    </Typography>
                  </Box>
                  <Box sx={{ mt: 4 }}>
                    <Typography variant="body2">
                      Restore your existing Pesa Wyse Wallet, import private key
                      from any other one to create a new Wallet right now!
                    </Typography>
                  </Box>
                  <Box sx={{ mt: 3 }}>
                    <Button variant="contained" fullWidth size="large" onClick={
                        ()=> history.push("/createwallet")
                    }>
                      Create a Wallet
                    </Button>

                    <Button sx={{ mt: 2 }} fullWidth size="large" onClick={
                        ()=> history.push("/restorewallet")
                    }>
                      Restore or import
                    </Button>
                  </Box>
                </CardContent>
              </Card>
            </Box>
            <Box display={"flex"} justifyContent={"center"}>
              <Card
                sx={{ width: 600, borderRadius: "0px", bgcolor: "#424242" }}
                elevation={0}
              >
                <CardContent sx={{ p: 4 }}>
                  <a href="/" style={{ color: "#A1A1A1", fontSize: "15px" }}>
                    What exactly is Pesa Wyse Wallet?
                  </a>
                  <Box sx={{ mt: 1 }}>
                    <a href="" style={{ color: "#A1A1A1", fontSize: "15px" }}>
                      How to import another wallet here?
                    </a>
                  </Box>
                </CardContent>
              </Card>
            </Box>
          </Grid>
         
        </Grid>
      </div>
    </div>
  );
}
