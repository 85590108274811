import { Button, Card, CardContent, Grid, TextField, Typography } from "@mui/material"
import { Box } from "@mui/system"
import Navbar from "../../Layout/Navbar/Navbar";
import trustP from "../../assets/trust.png";
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import { Footer } from "../../Layout/Footer/Index";

const advantages = [
    {
        image: "https://guarda.com/assets/images/exchange/no-limits.svg",
        title: "No Limits",
        subtitle: "We do not set any limits, you can make as many exchanges as you need."
    },
    {
        image: "https://guarda.com/assets/images/exchange/anonymous.svg",
        title: "Anonymous",
        subtitle: "You do not have to go through an authorization procedure, so all your exchanges will be completely anonymous"
    },

    {
        image: "https://guarda.com/assets/images/exchange/fast.svg",
        title: "Fast",
        subtitle: "The exchanges that take place at Guarda are made in a matter of minutes, allowing you to save time."
    },
    {
        image: "https://guarda.com/assets/images/exchange/fair.svg",
        title: "Fair",
        subtitle: "Our exchange rates are carefully selected so that our customers are satisfied and know that our offers are among the best on the market."
    },
]

export const Exchange = () => {
    return (
        <>
            <Navbar />
            <div className="container">
                <Grid
                    container
                    spacing={6}

                    justifyContent="space-between"
                    alignItems={"center"}
                    alignContent="center"


                    className="bodys"
                >
                    <Grid item sx={12} md={6}>

                        <Box >

                            <Typography fontWeight={"bold"} variant="h3">Fast Crypto Exchange

                            </Typography>
                            <Typography variant="body1" sx={{ mt: 1 }}>
                            Pesa Wyse platform is the fastest way to exchange any crypto-assets without registrations, KYC and long waits. It is one of the best crypto to crypto exchanges that established trust for a long time in the market.
                            </Typography>
                        </Box>
                        <Box display="flex" alignContent={"center"} justifyContent="space-between">
                            <img src={trustP} alt="image" width={"150px"} />

                            <Box>
                                <Typography variant="h6">Change</Typography>
                                <Typography color="primary" variant="h5">Now</Typography>
                            </Box>

                        </Box>

                    </Grid>
                    <Grid item xs={12} md={6} sx={{ mb: 3 }}>
                        <Card variant="outlined" sx={{ height: 400 }}>
                            <CardContent>
                                <Typography textAlign={"center"} fontWeight="bold">Exchange Crypto
                                </Typography>
                                <Grid container sx={{ mt: 5 }}>
                                    <Grid item xs={7}>
                                        <TextField fullWidth className="TextField-without-border-radius" />

                                    </Grid>
                                    <Grid item xs={5}>
                                        <TextField fullWidth className="TextField-without-border-radius" />

                                    </Grid>
                                </Grid>

                                <Grid container sx={{ mt: 5 }}>
                                    <Grid item xs={7}>
                                        <TextField fullWidth className="TextField-without-border-radius" />

                                    </Grid>
                                    <Grid item xs={5}>
                                        <TextField fullWidth className="TextField-without-border-radius" />

                                    </Grid>
                                </Grid>
                                <Button fullWidth variant="contained" size="large" sx={{ mt: 8 }}>Exchange</Button>
                            </CardContent>
                        </Card>

                    </Grid>
                </Grid>
            </div>
            <Grid container sx={{ mt: 4 }} className="bodys" >
                <Grid item xs={12} >
                    <Typography textAlign={"center"} variant="h5" fontWeight={"bold"}>Advantages of Using Crypto Coins Exchange
                    </Typography>

                </Grid>
                <Grid container spacing={2} sx={{ mt: 3 }}>
                    {
                        advantages.map((item) => (
                            <Grid xs={12} md={3} sx={{ mt: 4 }} >
                                <Card sx={{ height: "300px", mx:1 }}>
                                    <CardContent>
                                        <Box display="flex" justifyContent={"center"}>
                                            <img src={item.image} width="80px" />

                                        </Box>
                                        <Typography fontWeight={"bold"} textAlign="center">{item.title}
                                        </Typography>
                                        <Typography
                                            textAlign="center"
                                            sx={{ my: 2 }}
                                        >
                                            {item.subtitle}


                                        </Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                        ))
                    }
                </Grid>

                <Grid xs={4} sx={{ mt: 4 }}>
                    <Box>
                        <Typography color="primary" fontWeight={"bold"} variant="h5">Trusted and secure</Typography>
                        <Typography fontWeight={"bold"} variant="h5">
                            Bitcoin & crypto exchange
                        </Typography>
                        <Typography sx={{ mt: 3 }}>Pesa Wyse platform is the fastest way to exchange any crypto-assets without registrations, KYC and long waits. It is one of the best crypto to crypto exchanges that established trust for a long time in the market.

                        </Typography>
                    </Box>

                </Grid>
                <Grid xs={8} sx={{ mt: 4, pl:4 }}>
                    <Box>
                        <Box>
                            <Box sx={{ display: "flex", justifyContent: "center" }}>
                                <Typography variant="h5" fontWeight={"bold"} >If You’re New to Pesa Wyse
                                </Typography>
                                <Typography color="primary" variant="h5" fontWeight={"bold"} sx={{ ml: 1 }}>Crypto Exchange Wallet</Typography>

                            </Box>
                            <Typography variant="subtitle2" sx={{ mt: 3 }}>Our task is to conduct safe and quality exchanges because we focus on our customers and strictly control the processes of our clients' time. Pesa Wyse cryptocurrency exchange online doesn't just work to provide services, but our team is constantly working to simplify and speed up the adoption of crypto assets in everyday life. Therefore, we understand to what extent it is important to create an ecosystem in which it will be convenient to conduct transactions and give clients the best exchange rates.
                            </Typography>
                        </Box>
                        <Box sx={{ mt: 5 }}>
                            <Box sx={{ display: "flex", justifyContent: "center" }}>
                                <Typography variant="h5" fontWeight={"bold"} >Pesa Wyse crypto trade exchange is
                                </Typography>
                                <Typography color="primary" variant="h5" fontWeight={"bold"} sx={{ ml: 1 }}>decentralized</Typography>

                            </Box>
                            <Typography variant="subtitle2" sx={{ mt: 3 }}>Our task Decentralization is a concept that refers to the fact that no single person, regulatory body, or organization of any nature has sufficient power to control the network.
                            </Typography>
                        </Box>
                    </Box>

                </Grid>


            </Grid>
            <Footer />
        </>
    )
}