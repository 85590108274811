import { Brightness2, } from "@mui/icons-material";
import { Button, Grid, TextField, Typography, Checkbox, Card, Snackbar, Alert } from "@mui/material";
import { Box } from "@mui/system";
import { useState } from "react";
import logo from "../../assets/logo.png";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";
import "./style.css";
import { signUp } from "../../Services/requests";
import Navbar from "../../Layout/Navbar/Navbar";
import { useHistory } from "react-router-dom";
import { LoadingButton } from "@mui/lab";

export const Register = () => {
    const [emailClick, setEmailClick] = useState(true);
    const [phoneClick, setPhoneClick] = useState(false);
    const [phone, setPhone] = useState(null);
    const [checked, setChecked] = useState(true);
    const history = useHistory();
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [loader, setLoader] = useState(false);
    const [msg, setMsg] = useState("");
    const [snackb, setSnackbar] = useState(false);
    const handleChange = (event) => {
        setChecked(event.target.checked);
    };
    const handleSubmit = async () => {
        setLoader(true);
        const data = {
            username: username,
            password: password
        }

        const res = await signUp(data)
        setLoader(false);
        if (res.status === 'success') {
            history.push("/")
        } else {
            setMsg(res.message);
            setSnackbar(true)

        }
    }
    return (
        <>
            <Navbar />

            <Snackbar open={snackb} autoHideDuration={3000} >
                <Alert severity="warning" sx={{ width: '100%' }}>
                    {msg}
                </Alert>
            </Snackbar>
            <Box
                sx={{ minHeight: "100vh", backgroundColor: 'primary.body' }}
                display="flex"
                alignItems={"center"}
                flexDirection={"column"}
            >



                <Grid container alignItems={"center"} className="bodys">
                    <Grid item xs={12} md={12}>
                        <Box display={"flex"} justifyContent="center">
                            <Box sx={{ maxWidth: 450, mt: 4 }} display="flex" flexDirection={"column"}>
                                <Card sx={{ p: 4 }} variant="outlined">
                                    <Typography fontWeight={"bold"} variant="h6">
                                        Create Personal Account

                                    </Typography>
                                    <Box
                                        display="flex"
                                        flexDirection={"column"}
                                        alignItems="start"
                                        sx={{ mt: 4 }}
                                    >
                                        <Typography>username</Typography>
                                        <TextField fullWidth onChange={(e) => setUsername(e.target.value)} />
                                    </Box>


                                    <Box sx={{ mt: 4 }}>
                                        <Typography>Password</Typography>
                                        <TextField fullWidth onChange={(e) => setPassword(e.target.value)} />
                                    </Box>
                                    {/* <Box sx={{ mt: 4 }}>
                            <Typography>Confirm Password</Typography>
                            <TextField fullWidth />
                        </Box> */}

                                    <Box width={500}>

                                    </Box>
                                    <Box sx={{ mt: 4 }}>
                                        {
                                            loader ? (

                                                <LoadingButton fullWidth loading={loader}
                                                    loadingIndicator="Loading…"
                                                    variant="outlined">loading</LoadingButton>
                                            ) : (<Button variant="contained" fullWidth size="large" onClick={handleSubmit}>
                                                Create Account
                                            </Button>)
                                        }
                                    </Box>



                                    <Box sx={{ mt: 5 }}>
                                        <Typography> have account? <Button onClick={() => history.push("/login")}>Login</Button> </Typography>

                                    </Box>
                                </Card>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={6}>

                    </Grid>
                </Grid>
            </Box>
        </>
    );
};
