import { To_LIGHT, To_DARK} from "../Services/actionTypes";

const Light = () => {
  return {
    type: To_LIGHT,
  };
};

const Dark = () => {
  return {
    type: To_DARK,
  };
};

export { Light, Dark };