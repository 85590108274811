import logo from './logo.svg';
import './App.css';
import { Home } from './Pages/Home/Index';
import { createTheme, ThemeProvider, responsiveFontSizes } from "@mui/material/styles";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { HashRouter } from "react-router-dom";
import { Login } from './Pages/Login/Index';
import { Register } from './Pages/Register/Index';
import E7 from './Pages/App/Index';
import CreateWallet from './Pages/App/CreateWallet';
import RestoreWallet from './Pages/App/RestoreWallet';
import { Backup } from './Components/DownloadBackup/Index';
import { Wallets } from './Pages/App/Wallets';
import { BuySell } from './Pages/App/BuySell';
import { Earn } from './Pages/App/Earn';
import { SettingPage } from './Pages/App/Settings';
import { CoinDetail } from './Components/CoinDetail';
import { Exchange } from './Pages/Exchange';
import { useState } from 'react';
import { useSelector, } from "react-redux";
import { Notifications } from './Pages/App/Notifications';
import Protected from './Routes/protected';




function App() {
  const mode = useSelector((state) => state.status.status);



  const light = {
    typography: {
      fontFamily: [
        'Nunito',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif'
      ].join(','),
    },
    palette: {
      mode: "light",
      primary: {
        light: "#90CAF9",
        main: "#108FF5",
        bg: '#fff',
        body: '#EBF2FF',
        dark: "#1565C0",
        contrastText: "#fff",
      },

      secondary: {
        light: "#8a44fd",
        main: "#510ac9",
        dark: "#000097",
        // contrastText: "#fff",
      },
      allVariants: {
        fontFamily: "'Montserrat', sans-serif",
        textTransform: "none",
      }
    },

  };
  const dark = {
    palette: {
      mode: "dark",
      primary: {
        light: "#90CAF9",
        main: "#108FF5",
        dark: "#1565C0",
        body: '#181818',
        bg: '#181818',
        avatar: '#272727',
        avatar1: '#000',
        contrastText: '#fff',
      },

    },
  };

  let theme = createTheme(light);
  theme = responsiveFontSizes(theme);

  return (
    <div className="app">
      <ThemeProvider theme={mode ? createTheme(dark) : createTheme(light)}>
        <HashRouter>
          <Router>
            <Switch>
              <Route exact path="/home">
                <Home />
              </Route>
              <Route exact path="/app">
                <E7 />
              </Route>
              <Route exact path="/login">
                <Login />
              </Route>
              <Route exact path="/register">
                <Register />
              </Route>
              <Route exact path="/createwallet">
                <CreateWallet />
              </Route>
              <Route exact path="/restorewallet">
                <RestoreWallet />
              </Route>
              <Route exact path="/downloadbackup">
                <Backup />
              </Route>
              <Route exact path="/">
                <Protected>
                  <Wallets />
                </Protected>
              </Route>
              <Route exact path="/buysell">
                <Protected>
                  <BuySell />
                </Protected>
              </Route>
              <Route exact path="/earn">
                <Protected> <Earn /></Protected>
              </Route>
              <Route exact path="/notification">
                <Protected>
                  <Notifications />
                </Protected>
              </Route>
              <Route exact path="/coin/:id">
                <CoinDetail />
              </Route>
              <Route exact path="/setting">
                <Protected>
                  <SettingPage />
                </Protected>
              </Route>
              <Route exact path="/exchange">
                <Protected>
                  <Exchange />
                </Protected>
              </Route>
            </Switch>
          </Router>
        </HashRouter>

      </ThemeProvider>


    </div>
  );
}

export default App;
